@charset "UTF-8";
:root {
  --mdc-theme-primary: #1976d2;
  --mdc-theme-secondary: #d81b60;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #b00020;
  --mdc-theme-on-primary: rgba(255, 255, 255, 0.87);
  --mdc-theme-on-secondary: rgba(255, 255, 255, 0.87);
  --mdc-theme-on-surface: #000;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: rgba(255, 255, 255, 0.87);
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #1976d2 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2) !important;
}

.mdc-theme--secondary {
  color: #d81b60 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #d81b60) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020 !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87)) !important;
}

.mdc-theme--on-secondary {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87)) !important;
}

.mdc-theme--on-surface {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #1976d2 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2) !important;
}

.mdc-theme--secondary-bg {
  background-color: #d81b60 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60) !important;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 1rem);
  line-height: 44px;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 44px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, none);
  padding: 0 8px 0 8px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 4px;
}

.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}

.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-button:active {
  outline: none;
}

.mdc-button:hover {
  cursor: pointer;
}

.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-button .mdc-button__ripple {
  border-radius: 4px;
}

.mdc-button:not(:disabled) {
  background-color: transparent;
}

.mdc-button:disabled {
  background-color: transparent;
}

.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}

[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}

.mdc-button:not(:disabled) {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
}

.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-button--raised {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-button--raised:hover, .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  padding: 0 15px 0 15px;
  border-width: 1px;
  border-style: solid;
}

.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}

.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-button:hover .mdc-button__ripple::before {
  opacity: 0.04;
}

.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-button .mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button--raised .mdc-button__ripple::before, .mdc-button--raised .mdc-button__ripple::after,
.mdc-button--unelevated .mdc-button__ripple::before,
.mdc-button--unelevated .mdc-button__ripple::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
}

.mdc-button--raised:hover .mdc-button__ripple::before,
.mdc-button--unelevated:hover .mdc-button__ripple::before {
  opacity: 0.08;
}

.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-button {
  height: 36px;
}

.mdc-card {
  border-radius: 4px;
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mdc-card .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}

.mdc-card--outlined {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}

[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left;
}

[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
}

.mdc-card__primary-action:hover::before {
  opacity: 0.04;
}

.mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before, .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
  padding: 11px;
}

.mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background::before,
.mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background::before {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

.mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

.mdc-checkbox.mdc-checkbox--selected:hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
}

.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-checkbox.mdc-checkbox--selected:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-checkbox.mdc-checkbox--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded--background-focused.mdc-checkbox--selected .mdc-checkbox__ripple::after {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

.mdc-checkbox .mdc-checkbox__background {
  top: 11px;
  left: 11px;
}

.mdc-checkbox .mdc-checkbox__background::before {
  top: -13px;
  left: -13px;
  width: 40px;
  height: 40px;
}

.mdc-checkbox .mdc-checkbox__native-control {
  top: 0px;
  right: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #d81b60;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #d81b60);
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

@keyframes mdc-checkbox-fade-in-background-8A000000secondary00000000secondary {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
  50% {
    border-color: #d81b60;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #d81b60);
    background-color: #d81b60;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #d81b60);
  }
}
@keyframes mdc-checkbox-fade-out-background-8A000000secondary00000000secondary {
  0%, 80% {
    border-color: #d81b60;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #d81b60);
    background-color: #d81b60;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #d81b60);
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-8A000000secondary00000000secondary;
}

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-8A000000secondary00000000secondary;
}

.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.38);
  background-color: transparent;
}

.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.38);
}

.mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: rgba(255, 255, 255, 0.87);
}

.mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: rgba(255, 255, 255, 0.87);
}

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: transparent;
  }

  .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
    border-color: GrayText;
    background-color: transparent;
  }

  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: GrayText;
  }

  .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: GrayText;
  }

  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  display: inline-flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__background .mdc-checkbox__background::before {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none !important;
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__background::before {
  position: absolute;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  transform: scale(1);
  opacity: 0.12;
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-checkbox__native-control {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}

.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox--touch {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}

.mdc-checkbox--touch .mdc-checkbox__native-control {
  top: -4px;
  right: -4px;
  left: -4px;
  width: 48px;
  height: 48px;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-checkbox .mdc-checkbox__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-checkbox.mdc-ripple-upgraded--unbounded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-checkbox.mdc-ripple-upgraded--foreground-activation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation .mdc-checkbox__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-checkbox .mdc-checkbox__ripple::before, .mdc-checkbox .mdc-checkbox__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}

.mdc-checkbox:hover .mdc-checkbox__ripple::before {
  opacity: 0.04;
}

.mdc-checkbox.mdc-ripple-upgraded--background-focused .mdc-checkbox__ripple::before, .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-checkbox:not(.mdc-ripple-upgraded) .mdc-checkbox__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-checkbox:not(.mdc-ripple-upgraded):active .mdc-checkbox__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-checkbox .mdc-checkbox__ripple::before,
.mdc-checkbox .mdc-checkbox__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::before,
.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-checkbox.mdc-ripple-upgraded .mdc-checkbox__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-checkbox__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none;
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}

.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-chip__icon--trailing {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}

[dir=rtl] .mdc-chip__icon--trailing, .mdc-chip__icon--trailing[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
}

.mdc-chip {
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  height: 32px;
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-width: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.mdc-chip .mdc-chip__ripple {
  border-radius: 16px;
}

.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
}

[dir=rtl] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir=rtl],
[dir=rtl] .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden),
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}

.mdc-chip .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}

.mdc-chip::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-chip .mdc-chip__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip__primary-action:focus {
  outline: none;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip__icon--leading,
.mdc-chip__icon--trailing {
  position: relative;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(25, 118, 210, 0.54);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #1976d2;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #1976d2);
}

.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}

.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-chip .mdc-chip__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-chip.mdc-ripple-upgraded--unbounded .mdc-chip__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-chip.mdc-ripple-upgraded--foreground-activation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-chip.mdc-ripple-upgraded--foreground-deactivation .mdc-chip__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-chip .mdc-chip__ripple::before,
.mdc-chip .mdc-chip__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-chip.mdc-ripple-upgraded .mdc-chip__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-chip .mdc-chip__ripple::before, .mdc-chip .mdc-chip__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
}

.mdc-chip:hover .mdc-chip__ripple::before {
  opacity: 0.04;
}

.mdc-chip.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-chip:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-chip:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-chip .mdc-chip__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before {
  opacity: 0.08;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__ripple::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover .mdc-chip__ripple::before {
  opacity: 0.12;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded:focus-within .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus .mdc-chip__ripple::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus-within .mdc-chip__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded) .mdc-chip__ripple::after {
  transition: opacity 150ms linear;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active .mdc-chip__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2;
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.mdc-chip-set .mdc-chip {
  margin: 4px;
}

.mdc-chip-set .mdc-chip--touch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
}

.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}

.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}

@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}

.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}

.mdc-dialog[dir=rtl] .mdc-dialog__surface, [dir=rtl] .mdc-dialog .mdc-dialog__surface {
  /* @noflip */
  text-align: right;
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
}

.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

.mdc-dialog[dir=rtl] .mdc-dialog__title, [dir=rtl] .mdc-dialog .mdc-dialog__title {
  /* @noflip */
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px;
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-dialog__content > :first-child {
  margin-top: 0;
}

.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}

.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  /* @noflip */
  text-align: right;
}

[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-dialog[dir=rtl] .mdc-dialog__button, [dir=rtl] .mdc-dialog .mdc-dialog__button {
  /* @noflip */
  text-align: left;
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}

.mdc-dialog--closing .mdc-dialog__container {
  transform: scale(1);
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}

.mdc-dialog--open .mdc-dialog__container {
  transform: scale(1);
  opacity: 1;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @noflip */
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-drawer .mdc-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: #1976d2;
}

.mdc-drawer .mdc-list-item--activated {
  color: rgba(25, 118, 210, 0.87);
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-radius: 0 0 0 0;
}

.mdc-drawer .mdc-list-item {
  border-radius: 4px;
}

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 256px;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 256px;
}

[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-right-width: 0;
  /* @noflip */
  border-left-width: 1px;
  /* @noflip */
  border-right-style: none;
  /* @noflip */
  border-left-style: solid;
}

.mdc-drawer .mdc-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}

.mdc-drawer .mdc-list-item:nth-child(1) {
  margin-top: 2px;
}

.mdc-drawer .mdc-list-item:nth-last-child(1) {
  margin-bottom: 0;
}

.mdc-drawer .mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}

.mdc-drawer .mdc-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer .mdc-list-divider {
  margin: 3px 0 4px;
}

.mdc-drawer .mdc-list-item__text,
.mdc-drawer .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}

[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}

[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  transform: translateX(0);
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}

[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}

.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}

[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative;
}

[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed;
}

.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}

[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}

.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}

.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}

.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-elevation--z0 {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  /* @alternate */
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  /* @alternate */
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  /* @alternate */
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  /* @alternate */
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  /* @alternate */
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  /* @alternate */
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  /* @alternate */
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  /* @alternate */
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  /* @alternate */
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  /* @alternate */
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  /* @alternate */
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.mdc-form-field > label {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
  order: 0;
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-form-field > label, .mdc-form-field > label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--align-end > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 4px;
  order: -1;
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end > label[dir=rtl] {
  /* @noflip */
  padding-left: 4px;
  /* @noflip */
  padding-right: 0;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}

.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}

.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
}

.mdc-icon-button:hover::before {
  opacity: 0.04;
}

.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  height: 48px;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item--disabled .mdc-list-item__text {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__text,
.mdc-list-item--disabled .mdc-list-item__primary-text,
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  overflow: hidden;
}

.mdc-list-item:focus {
  outline: none;
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
}

.mdc-list-item[dir=rtl] .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block;
}

.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}

.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}

.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}

.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block;
}

.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list--dense .mdc-list-item__secondary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-size: inherit;
}

.mdc-list--dense .mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px;
}

.mdc-list-item[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 36px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--avatar-list .mdc-list-item {
  height: 56px;
}

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.mdc-list-item[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 72px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px;
}

.mdc-list-item[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 20px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  margin: 0 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}

.mdc-list-group[dir=rtl] .mdc-list-divider--inset, [dir=rtl] .mdc-list-group .mdc-list-divider--inset {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px);
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  background-color: #000;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
  opacity: 0.04;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
  opacity: 0.12;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
  opacity: 0.16;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
  opacity: 0.08;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
  opacity: 0.12;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.2;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.2;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  background-color: #000;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--background-focused::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-menu {
  min-width: 112px;
}

.mdc-menu .mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu .mdc-list {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  position: relative;
}

.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}

.mdc-menu .mdc-list-divider {
  margin: 8px 0;
}

.mdc-menu .mdc-list-item {
  user-select: none;
}

.mdc-menu .mdc-list-item--disabled {
  cursor: auto;
}

.mdc-menu a.mdc-list-item .mdc-list-item__text,
.mdc-menu a.mdc-list-item .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}

.mdc-menu__selection-group .mdc-list-item {
  /* @noflip */
  padding-left: 56px;
  /* @noflip */
  padding-right: 16px;
}

[dir=rtl] .mdc-menu__selection-group .mdc-list-item, .mdc-menu__selection-group .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 56px;
}

.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

@keyframes mdc-linear-progress-primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
  }
  100% {
    transform: translateX(200.611057%);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
  }
  100% {
    transform: translateX(160.277782%);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes mdc-linear-progress-buffering {
  to {
    transform: translateX(-10px);
  }
}
@keyframes mdc-linear-progress-primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
  }
  100% {
    transform: translateX(-200.611057%);
  }
}
@keyframes mdc-linear-progress-secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
  }
  100% {
    transform: translateX(-160.277782%);
  }
}
@keyframes mdc-linear-progress-buffering-reverse {
  to {
    transform: translateX(10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  transform: translateZ(0);
  outline: 1px solid transparent;
  overflow: hidden;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  animation: none;
  border-top: 4px solid;
}

.mdc-linear-progress__buffering-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: 10px 4px;
  animation: mdc-linear-progress-buffering 250ms infinite linear;
}

.mdc-linear-progress__buffer {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}

.mdc-linear-progress__secondary-bar {
  visibility: hidden;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
  animation: mdc-linear-progress-primary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-primary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  visibility: visible;
  animation: mdc-linear-progress-secondary-indeterminate-translate 2s infinite linear;
}

.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: mdc-linear-progress-secondary-indeterminate-scale 2s infinite linear;
}

.mdc-linear-progress--reversed .mdc-linear-progress__bar,
.mdc-linear-progress--reversed .mdc-linear-progress__buffer {
  right: 0;
  transform-origin: center right;
}

.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  animation-name: mdc-linear-progress-primary-indeterminate-translate-reverse;
}

.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  animation-name: mdc-linear-progress-secondary-indeterminate-translate-reverse;
}

.mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
  animation: mdc-linear-progress-buffering-reverse 250ms infinite linear;
}

.mdc-linear-progress--closed {
  opacity: 0;
  animation: none;
}

.mdc-linear-progress__bar-inner {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer {
  background-color: #e6e6e6;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right;
}

.mdc-menu-surface:focus {
  outline: none;
}

.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}

.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}

[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /* @noflip */
  transform-origin-left: top right;
  /* @noflip */
  transform-origin-right: top left;
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}

[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}

.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}

[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}

.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}

[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}

.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}

.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}

[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  padding: 0;
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  outline: none;
  overflow: hidden;
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
}

.mdc-ripple-surface:hover::before {
  opacity: 0.04;
}

.mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
  overflow: visible;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-ripple-surface--primary:hover::before {
  opacity: 0.04;
}

.mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

.mdc-ripple-surface--accent:hover::before {
  opacity: 0.04;
}

.mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before, .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-ripple-surface--accent.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}

.mdc-line-ripple::before {
  border-bottom-width: 1px;
  z-index: 1;
}

.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-select--with-leading-icon:not(.mdc-select--disabled) .mdc-select__icon {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  position: absolute;
  bottom: 16px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  fill: currentColor;
  opacity: 0.54;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-select-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}

.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-select {
  position: relative;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: whitesmoke;
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-select:not(.mdc-select--disabled) .mdc-select__anchor + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-select .mdc-select__anchor {
  border-radius: 4px 4px 0 0;
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #1976d2;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(25, 118, 210, 0.87);
}

.mdc-select:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mdc-select .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 21px;
  pointer-events: none;
}

[dir=rtl] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}

.mdc-select.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
  top: 17px;
}

[dir=rtl] .mdc-select.mdc-select--outlined .mdc-floating-label, .mdc-select.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}

.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}

.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}

.mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
  /* @noflip */
  left: auto;
  /* @noflip */
  right: 8px;
  position: absolute;
  bottom: 16px;
  width: 24px;
  height: 24px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}

[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /* @noflip */
  left: 8px;
  /* @noflip */
  right: auto;
}

.mdc-select--focused .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%231976d2%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}

.mdc-select--activated .mdc-select__dropdown-icon {
  transform: rotate(180deg) translateY(-5px);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-select__anchor {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}

.mdc-select__anchor::before, .mdc-select__anchor::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-select__anchor::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-select__anchor.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-select__anchor.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-select__anchor.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-select__anchor::before, .mdc-select__anchor::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-select__anchor.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-select__anchor::before, .mdc-select__anchor::after {
  background-color: rgba(0, 0, 0, 0.87);
}

.mdc-select__anchor:hover::before {
  opacity: 0.04;
}

.mdc-select__anchor.mdc-ripple-upgraded--background-focused::before, .mdc-select__anchor:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.75);
}

.mdc-select__anchor.mdc-select--focused .mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select__anchor + .mdc-select-helper-text {
  margin-right: 12px;
  margin-left: 12px;
}

.mdc-select--outlined .mdc-select__anchor + .mdc-select-helper-text {
  margin-right: 16px;
  margin-left: 16px;
}

.mdc-select--focused .mdc-select__anchor + .mdc-select-helper-text:not(.mdc-select-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  box-sizing: border-box;
  width: 100%;
  min-width: 200px;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}

[dir=rtl] .mdc-select__selected-text, .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-select__selected-text::-ms-expand {
  display: none;
}

.mdc-select__selected-text::-ms-value {
  background-color: transparent;
  color: inherit;
}

@-moz-document url-prefix("") {
  .mdc-select__selected-text {
    text-indent: -2px;
  }
}
.mdc-select--outlined {
  border: none;
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

.mdc-select--outlined .mdc-select__selected-text {
  border-radius: 4px;
}

.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--outlined .mdc-select__anchor {
  overflow: visible;
}

.mdc-select--outlined .mdc-select__anchor::before, .mdc-select--outlined .mdc-select__anchor::after {
  content: none;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  transform: translateY(-144%) scale(1);
}

.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) scale(0.75);
}

.mdc-select--outlined .mdc-select__anchor.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-select__anchor .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--outlined .mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  display: flex;
  padding-top: 14px;
  padding-bottom: 12px;
  border: none;
  background-color: transparent;
  z-index: 1;
}

[dir=rtl] .mdc-select--outlined .mdc-select__selected-text, .mdc-select--outlined .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}

.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  pointer-events: auto;
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
}

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid .mdc-select__anchor + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid:not(.mdc-select--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-select--invalid .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23b00020%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}

.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  opacity: 1;
}

.mdc-select--required .mdc-floating-label::after {
  content: "*";
}

.mdc-select--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-select--disabled .mdc-select__anchor {
  background-color: #fafafa;
}

.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select--disabled .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}

.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.38);
}

.mdc-select--disabled .mdc-line-ripple::before {
  border-bottom-style: dotted;
}

.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}

.mdc-select--disabled.mdc-select--outlined .mdc-select__anchor {
  background-color: transparent;
}

.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__leading,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.16);
}

.mdc-select--no-label:not(.mdc-select--outlined) .mdc-select__anchor .mdc-select__selected-text {
  padding-top: 14px;
}

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-select--with-leading-icon .mdc-select__selected-text {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 32px;
}

[dir=rtl] .mdc-select--with-leading-icon .mdc-select__selected-text, .mdc-select--with-leading-icon .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 48px;
}

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1);
}

[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-144%) translateX(32px) scale(1);
}

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75);
}

[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(32px) scale(0.75);
}

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon 250ms 1;
}

[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, .mdc-select--with-leading-icon.mdc-select--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl 250ms 1;
}

.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px;
}

[dir=rtl] .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text, .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text[dir=rtl] {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}

.mdc-select__menu .mdc-list .mdc-list-item--selected:hover::before {
  opacity: 0.04;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before, .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
}
.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}

@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}
.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 12px;
}

[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}

[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding-top: 14px;
  padding-bottom: 14px;
}

[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}

.mdc-snackbar__action::before, .mdc-snackbar__action::after {
  background-color: #bb86fc;
}

.mdc-snackbar__action:hover::before {
  opacity: 0.08;
}

.mdc-snackbar__action.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__dismiss::before, .mdc-snackbar__dismiss::after {
  background-color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__dismiss:hover::before {
  opacity: 0.08;
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 9px;
  font-size: 18px;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss svg,
.mdc-snackbar__dismiss.mdc-snackbar__dismiss img {
  width: 18px;
  height: 18px;
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 1rem);
  line-height: 44px;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 44px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, none);
  padding-right: 24px;
  padding-left: 24px;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}

.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}

.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  width: 24px;
  height: 24px;
  font-size: 24px;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
}

.mdc-tab--active .mdc-tab__icon {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  fill: currentColor;
}

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
}

.mdc-tab__ripple {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-tab__ripple.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab__ripple.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-tab__ripple.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-tab__ripple.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-tab__ripple.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-tab__ripple.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-tab__ripple:hover::before {
  opacity: 0.04;
}

.mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before, .mdc-tab__ripple:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-tab__ripple:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-tab__ripple:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-tab__ripple.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}

.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #d81b60;
  /* @alternate */
  color: var(--mdc-theme-secondary, #d81b60);
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}

.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap;
}

.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-text-field .mdc-text-field__ripple::before,
.mdc-text-field .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}

.mdc-text-field .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}

.mdc-text-field.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-text-field.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}

.mdc-text-field.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}

.mdc-text-field.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}

.mdc-text-field.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}

.mdc-text-field .mdc-text-field__ripple::before,
.mdc-text-field .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}

.mdc-text-field.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  height: 56px;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}

.mdc-text-field .mdc-text-field__ripple::before,
.mdc-text-field .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field:hover .mdc-text-field__ripple::before {
  opacity: 0.04;
}

.mdc-text-field.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}

@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #1976d2;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field .mdc-line-ripple::after {
  border-bottom-color: #1976d2;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}

.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-text-field--textarea .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}

.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}

.mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}

.mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */
  left: 40px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 40px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  padding: 20px 16px 7px;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-text-field__input::placeholder {
  transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.mdc-text-field--fullwidth .mdc-text-field__input::placeholder, .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
  transition-delay: 40ms;
  transition-duration: 110ms;
  opacity: 1;
}

.mdc-text-field__input:focus {
  outline: none;
}

.mdc-text-field__input:invalid {
  box-shadow: none;
}

.mdc-text-field__input:-webkit-autofill {
  z-index: auto !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding-top: 0;
  padding-bottom: 1px;
}

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto;
}

.mdc-text-field--outlined {
  overflow: visible;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}

.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  content: none;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mdc-text-field--outlined .mdc-text-field__input {
  padding-top: 12px;
  padding-bottom: 14px;
  display: flex;
  border: none !important;
  background-color: transparent;
  z-index: 1;
}

.mdc-text-field--outlined .mdc-text-field__icon {
  z-index: 2;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}

@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px;
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) scale(1);
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem;
}

.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) scale(0.8);
}

.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 7px;
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  top: 14px;
}

.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
  top: 12px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon--leading {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__icon--leading, .mdc-text-field--with-leading-icon .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}

[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon--leading {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon--leading, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) translateX(-21px) scale(1);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-134%) translateX(21px) scale(1);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) translateX(-21px) scale(0.8);
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-120%) translateX(21px) scale(0.8);
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 32px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 32px;
}

.mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing, .mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}

[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--leading {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--leading, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  padding-right: 48px;
  padding-left: 48px;
}

.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8);
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon--leading {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon--leading, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 16px;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 44px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 44px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 44px;
}

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 44px;
}

[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--leading {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--leading, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  padding-right: 44px;
  padding-left: 44px;
}

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.8);
}

.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1;
}

.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0;
}

.mdc-text-field--dense .mdc-floating-label {
  font-size: 0.813rem;
}

.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.813rem;
}

.mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  margin-left: 1px;
  content: "*";
}

.mdc-text-field--textarea {
  display: inline-flex;
  width: auto;
  height: auto;
  overflow: visible;
  transition: none;
}

.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
}

.mdc-text-field--textarea .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea 250ms 1;
}

.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

[dir=rtl] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}

[dir=rtl] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}

.mdc-text-field--textarea .mdc-text-field__ripple::before,
.mdc-text-field--textarea .mdc-text-field__ripple::after {
  content: none;
}

.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mdc-text-field--textarea .mdc-floating-label--float-above {
  transform: translateY(-144%) scale(1);
}

.mdc-text-field--textarea .mdc-floating-label--float-above {
  font-size: 0.75rem;
}

.mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) scale(0.75);
}

.mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}

.mdc-text-field--textarea .mdc-text-field-character-counter {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
  position: absolute;
  bottom: 13px;
}

[dir=rtl] .mdc-text-field--textarea .mdc-text-field-character-counter, .mdc-text-field--textarea .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}

.mdc-text-field--textarea .mdc-text-field__input {
  align-self: auto;
  box-sizing: border-box;
  height: auto;
  margin: 8px 1px 1px 0;
  padding: 0 16px 16px;
  line-height: 1.75rem;
}

.mdc-text-field--textarea .mdc-text-field-character-counter + .mdc-text-field__input {
  margin-bottom: 28px;
  padding-bottom: 0;
}

.mdc-text-field--textarea .mdc-floating-label {
  top: 17px;
  width: auto;
  pointer-events: none;
}

.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}

.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-text-field--fullwidth {
  width: 100%;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--fullwidth.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  display: block;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__ripple::before,
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__ripple::after {
  content: none;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0 0 1px;
}

.mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
  resize: vertical;
}

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.mdc-text-field--dense + .mdc-text-field-helper-line {
  margin-bottom: 4px;
}

.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(25, 118, 210, 0.87);
}

.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--disabled {
  background-color: #fafafa;
  pointer-events: none;
}

.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}

@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}

.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}

@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}

.mdc-text-field--textarea.mdc-text-field--disabled {
  background-color: transparent;
  /* @alternate */
  background-color: #f9f9f9;
}

.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}

@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}

[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  text-align: left;
}

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-34.75px) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-34.75px) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -21px)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -21px)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8);
  }
}
@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
}
.mdc-top-app-bar {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
}

.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
  opacity: 0.08;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}

.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}

.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}

.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}

.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}

[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-radius: 0 0 24px 0;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-radius: 0 0 0 24px;
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
}

.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}

.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}

.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}

[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }

  .mdc-top-app-bar__section {
    padding: 4px;
  }

  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }

  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }

  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
/**
 * This fixes a bug with icons consisting of 2 glyphs like "archived".
 */
.material-icons,
.material-icons-outlined {
  display: block !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.material-icons:not(.mdc-button__icon):not(.mdc-tab__icon),
.material-icons-outlined:not(.mdc-button__icon):not(.mdc-tab__icon) {
  color: #424242;
}

body.desktop {
  --top-app-bar-height: 64px;
  --bottom-app-bar-height: 0;
  --app-bar-button-height: 36px;
}

body.mobile {
  --top-app-bar-height: 56px;
  --bottom-app-bar-height: 56px;
  --app-bar-button-height: 32px;
}

.material-icons:after {
  content: attr(data-icon);
}

body .mdc-top-app-bar {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  height: var(--top-app-bar-height);
  background-color: rgba(0, 0, 0, 0.8);
}
body .mdc-top-app-bar .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
body .mdc-top-app-bar .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
body .mdc-top-app-bar .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
body .mdc-top-app-bar__row {
  justify-content: inherit;
  padding: 0 16px;
}
body .mdc-top-app-bar__section {
  flex: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
body .mdc-top-app-bar__section--align-end {
  flex: 1 !important;
}
.mobile .mdc-top-app-bar .mdc-top-app-bar__section--align-end {
  margin-left: -10px;
}

body .mdc-top-app-bar .mdc-button {
  height: var(--app-bar-button-height);
}
body .mdc-top-app-bar__title {
  user-select: none;
  flex: 1;
  justify-content: center;
  letter-spacing: 0;
}
body .mdc-dialog {
  z-index: 1001;
}
body .mdc-dialog__title, body .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}
body .mdc-dialog__content {
  position: relative;
}
body .mdc-snackbar {
  z-index: 1999;
  margin-bottom: env(safe-area-inset-bottom);
}
body .mdc-snackbar .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
body .mdc-snackbar .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
body .mdc-snackbar .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
body .mdc-snackbar__label {
  padding-right: 0;
}
body .mdc-snackbar__dismiss {
  margin-left: 0;
}
body .mdc-select {
  height: 56px;
}
body .mdc-select__anchor {
  overflow-x: hidden;
  width: 100%;
}
body .mdc-select__selected-text {
  overflow-x: hidden;
  display: block !important;
  text-overflow: ellipsis;
}
body .mdc-select__menu {
  z-index: 1002;
}
body .mdc-select__menu .mdc-list-item {
  white-space: nowrap;
  overflow-x: hidden;
}
body .mdc-linear-progress__buffering-dots {
  animation: none;
}
body .mdc-icon-button:disabled {
  color: inherit;
  opacity: 0.28;
}
body .mdc-list-item {
  height: unset;
  min-height: 48px;
}
body .mdc-list-item--disabled .material-icons {
  opacity: 0.28;
}
body .mdc-list-item__graphic {
  margin-right: 24px;
  width: 28px;
  height: 28px;
}
body .mdc-button--outlined:not(:disabled) {
  border-color: inherit;
}
body .mdc-chip__icon {
  color: #616161 !important;
}
body .mdc-chip[disabled] {
  opacity: 0.28;
}
body .mdc-checkbox--disabled {
  opacity: 0.28;
}
body .mdc-drawer__content {
  padding-bottom: env(safe-area-inset-bottom);
}
body .mdc-form-field label {
  cursor: pointer;
  user-select: none;
}
body .mdc-card,
body .mdc-dialog .mdc-dialog__surface {
  border-radius: 28px;
}
body .mdc-button,
body .mdc-button .mdc-button__ripple {
  border-radius: 32px;
}
body .mdc-button {
  padding: 0 16px;
  height: 40px;
}
body .mdc-button > .material-icons {
  margin-left: 0;
  font-size: 24px;
  height: 24px;
  width: 24px;
}
body .mdc-notched-outline__leading {
  border-radius: 8px 0 0 8px !important;
}
body .mdc-notched-outline__trailing {
  border-radius: 0 8px 8px 0 !important;
}
body .mdc-dialog__actions {
  padding: 16px;
}
body .mdc-dialog__button {
  margin-left: 0;
}
body .mdc-chip {
  border-radius: 8px;
}

.mdc-text-field {
  flex-shrink: 0;
}

body.mobile .mdc-top-app-bar__title {
  padding-left: 6px;
  justify-content: left;
}
body.mobile .mdc-top-app-bar__row {
  padding: 0 8px;
}

.mdc-menu {
  z-index: 1000;
}

.mdc-button .mdc-button__label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input::-ms-clear {
  display: none;
}

.mdc-tab__icon {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.mdc-page__main {
  padding-top: var(--top-app-bar-height);
}

/**
 * Extensions to the regular MDC styles.
 */
.mdc-snackbar--open + .opening,
.mdc-snackbar--open + .mdc-snackbar--open,
.mdc-snackbar--open + .mdc-snackbar--closing {
  transform: translate(0, -100%);
  margin-bottom: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
}
.image-grid > * {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  min-width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.image-grid > *::before, .image-grid > *::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.image-grid > *::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.image-grid > *.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.image-grid > *.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.image-grid > *.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.image-grid > *.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.image-grid > *.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.image-grid > *::before, .image-grid > *::after {
  background-color: #000;
}
.image-grid > *:hover::before {
  opacity: 0.04;
}
.image-grid > *.mdc-ripple-upgraded--background-focused::before, .image-grid > *:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.image-grid > *:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.image-grid > *:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.image-grid > *.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.image-grid > *::before, .image-grid > *::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.image-grid > *.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.image-grid > *[aria-selected=true] {
  border-radius: 10px;
  border: 2px solid white;
  outline: 3px solid #1e88e5;
}
.image-grid--wide {
  grid-template-columns: repeat(auto-fill, minmax(112px, 1fr));
}
.image-grid--wide > * {
  min-width: 112px;
}
.image-grid--medium {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-row-gap: 12px;
  grid-column-gap: 12px;
}
.image-grid--medium > * {
  min-width: 160px;
  height: 160px;
}
.image-grid--large {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 24px;
}
.image-grid--large > * {
  min-width: 240px;
  height: 240px;
}
.image-grid--large .image-grid-item__title {
  margin-top: -2px;
  text-align: left;
}
.image-grid.image-grid--dense {
  grid-row-gap: 1px;
  grid-column-gap: 1px;
}
.image-grid.image-grid--dense > * {
  box-shadow: none !important;
  border-radius: 5px;
  width: 100%;
  min-width: unset;
  height: auto;
}
.image-grid-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.image-grid-item__image {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-grid-item__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600 !important;
  padding: 12px;
  text-align: center;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 600px) {
  .mdcx-dialog__container--wide .mdc-dialog__surface {
    max-width: 860px;
  }
}
.mdcx-dialog__container--wide .mdc-dialog__surface,
.mdcx-dialog__container--fit .mdc-dialog__surface {
  width: calc(100vw - 32px);
}

.mdcx-dialog__container--no-scroll .mdc-dialog__content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  will-change: unset !important;
}

.mdcx-icon-button:after {
  top: inherit !important;
  left: inherit !important;
  width: inherit !important;
  height: inherit !important;
  background-color: transparent !important;
  position: inherit !important;
  border-radius: inherit !important;
  opacity: 1 !important;
}
.mdcx-icon-button--with-label {
  width: 56px !important;
  height: 56px !important;
  padding-top: 0 !important;
  padding-bottom: 14px !important;
}
.mdcx-icon-button--with-label .mdcx-badge {
  right: 10px;
}
.mdcx-icon-button--with-label .mdcx-badge--small {
  right: 17px;
}
.mdcx-icon-button--with-label.mdcx-icon-button--small {
  width: 52px !important;
  height: 52px !important;
}
.mdcx-icon-button--with-label.mdcx-icon-button--small .mdcx-icon-button__label {
  bottom: 2px;
}
.mdcx-icon-button__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.mdcx-icon-button--small {
  width: 32px;
  height: 32px;
  font-size: 20px;
  padding: 6px;
}

.mdcx-chip--small {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  height: 24px;
}
.mdcx-chip--small .mdc-chip__icon {
  font-size: 20px !important;
}

.mdcx-chip--primary {
  background-color: #1976d2 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2) !important;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87)) !important;
}
.mdcx-chip--primary .mdc-chip__icon {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87)) !important;
  font-size: 20px !important;
}

.mdcx-icon--small {
  font-size: 20px;
  padding: 6px;
}
.mdcx-icon--inline {
  display: inline-block !important;
  vertical-align: middle;
  padding: 0 2px;
}
.mdcx-icon--not-pressed {
  opacity: 0.667;
}

/* Our extensions to MDC (mostly because MDC does not provide the component yet. */
.mdcx-icon-button {
  cursor: pointer;
}

/* We use our own version of `fullwidth` here, because the original version is buggy. */
.mdcx-text-field--fullwidth,
.mdcx-text-field--fullwidth > input {
  width: 100%;
}

.mdcx-avatar {
  background-color: #bdbdbd;
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-position: center center;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid white;
  padding-left: 1px;
}
.mdcx-avatar .material-icons {
  font-size: 29px;
}
.mdcx-avatar--very-large {
  height: 80px;
  width: 80px;
  min-height: 80px;
  min-width: 80px;
}
.mdcx-avatar--very-large .material-icons {
  font-size: 77px;
}
.mdcx-avatar--large {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
}
.mdcx-avatar--large .material-icons {
  font-size: 47px;
}
.mdcx-avatar--small {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
}
.mdcx-avatar--small .material-icons {
  font-size: 19px;
}
.mdcx-avatar--has-icon {
  background-color: #fafafa;
}
.mdcx-avatar--has-image {
  background-color: white;
}
.mdcx-avatar__letter {
  font-size: 14px;
}
.mdcx-avatar--large .mdcx-avatar__letter {
  font-size: 20px;
}

.mdcx-cursor--pointer {
  cursor: pointer;
}

.mdcx-badge {
  background-color: #fafafa;
  overflow: hidden;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  margin-left: 12px;
  margin-top: -8px;
  z-index: 1;
  font-size: 11px;
  padding-top: 4px;
  font-family: Proxima, Arial, sans-serif;
  font-weight: 600;
}
.mdcx-badge--disabled {
  opacity: 0.5;
}
.mdcx-badge--secondary {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87));
}
.mdcx-badge--small {
  margin-top: -2px;
  margin-left: 16px;
  width: 10px;
  height: 10px;
}

.mdcx-button-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.mdcx-button-bar--no-outlined-buttons {
  margin-left: -16px;
  gap: 0;
}
.mdcx-button-bar > * {
  margin: 0;
}

.mdcx-list-item--danger {
  color: #f44336 !important;
}

.mdc-card > .mdcx-button-bar {
  margin-top: 16px;
}

.mdcx-button--secondary {
  color: #d81b60 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #d81b60) !important;
}
.mdcx-button--secondary.mdc-button--raised {
  background-color: #d81b60 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60) !important;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87)) !important;
}
.mdcx-button--danger {
  color: #f44336 !important;
}
.mdcx-button--danger.mdc-button--raised {
  background-color: #f44336 !important;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87)) !important;
}

.mdcx-select__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  padding: 0 48px 0 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
}
.mdcx-select__input:focus {
  outline: none;
}

a.mdcx-link--outgoing {
  color: #1976d2 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2) !important;
  text-decoration: underline;
  outline: 0;
}

.mdcx-list-divider--with-title {
  margin: 16px 0 !important;
}
.mdcx-list-divider--with-title .mdcx-list-divider__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #757575;
  position: absolute;
  margin-top: -10px;
  margin-left: 12px;
  padding-left: 6px;
  padding-right: 6px;
}

.mdcx-list-item--disabled {
  opacity: 0.26;
}

.mdcx-tab--dense {
  padding: 0 !important;
}

body .mdcx-bottom-app-bar {
  bottom: 0;
  height: calc(var(--bottom-app-bar-height) + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
}
body .mdcx-bottom-app-bar .mdc-top-app-bar__row {
  padding-left: 0;
  padding-right: 0;
}

body.desktop .mdcx-bottom-app-bar {
  box-shadow: none !important;
  background-color: transparent !important;
}

.mdcx-menu__search {
  padding: 0 8px;
}
.mdcx-menu__search-hidden {
  display: none !important;
}

.mdcx-menu__surface {
  display: none;
  flex-direction: column;
  padding: 0 8px;
}
.mdcx-menu__surface-middle {
  margin-left: -8px;
  margin-right: -8px;
  overflow-y: auto;
  flex: 1;
  position: relative;
}
.mdcx-menu__surface-last {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdcx-menu__surface-first {
  margin-top: 16px;
  margin-bottom: 16px;
}

.ps__rail-x .ps__thumb-x,
.ps__rail-y .ps__thumb-y {
  background-color: #616161 !important;
  box-shadow: inset 1px 0 0 0 #e0e0e0, inset -1px 0 0 0 #e0e0e0, inset 0 1px 0 0 #e0e0e0, inset 0 -1px 0 0 #e0e0e0 !important;
}

.ps:hover > .ps__rail-y, .ps:hover > .ps__rail-y {
  opacity: 0.58 !important;
}
.ps:hover > .ps__rail-y:hover, .ps:hover > .ps__rail-y.ps--clicking, .ps:hover > .ps__rail-y:hover, .ps:hover > .ps__rail-y.ps--clicking {
  opacity: 0.9 !important;
}

.ps__rail-y {
  display: block !important;
  width: 10px !important;
  border-radius: 6px;
}
.ps__rail-y .ps__thumb-y {
  width: 10px !important;
  right: 0 !important;
}

.ps__rail-x {
  display: block !important;
  border-radius: 6px;
}

.ps__rail-y {
  right: 0 !important;
  left: unset !important;
}

.ps__rail-x {
  top: unset !important;
  bottom: 0 !important;
}

.ps--always-visible .ps__rail-y {
  opacity: 0.6 !important;
}
.ps--always-visible .ps__rail-x {
  opacity: 0.6 !important;
}

.suggestion-textfield--selected::before {
  opacity: 0.12 !important;
}

.suggestion-textfield__menu-surface {
  z-index: 1002;
}

.searchable-list {
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin-left: -16px;
  margin-right: -16px;
}
.searchable-list__middle {
  overflow-y: auto;
  flex: 1;
  position: relative;
}
.searchable-list__last {
  margin-top: 16px;
  margin-bottom: 16px;
}
.searchable-list__fold {
  width: 100%;
  padding: 0 7px;
  display: flex;
  justify-content: center;
}
.searchable-list__first {
  margin: 16px 8px;
}

.mdcx-card__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  margin-bottom: 8px;
}

.mdcx-date-field {
  align-items: center;
}
.mdcx-date-field__clear {
  font-size: 15px;
  margin-right: 4px;
  margin-left: -12px;
  z-index: 1;
}
.mdcx-date-field input {
  min-width: 110px;
}

.mdcx-date-time-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
  justify-content: flex-end;
}
.mdcx-date-time-field > * {
  flex: 1;
}
.mdcx-date-time-field > *:last-child {
  margin-left: 16px;
}

.mdcx-simple-tooltip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  border-radius: 10px;
  padding: 4px 12px;
  background-color: #424242;
  border: 1px solid #9e9e9e;
  z-index: 2002;
  position: absolute;
  transition: opacity 350ms ease;
  white-space: pre;
}
.mdcx-simple-tooltip > :first-letter {
  text-transform: uppercase;
}

.flatpickr-calendar {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: unset !important;
  padding: 16px;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}
.flatpickr-calendar input:hover {
  background-color: #eee;
}
.flatpickr-calendar .flatpickr-day.selected {
  background-color: #1976d2 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2) !important;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87)) !important;
  border: none !important;
  border-radius: 50%;
}
.flatpickr-calendar .flatpickr-day.today {
  borde-color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  borde-color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87)) !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}
.flatpickr-calendar select {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border: none !important;
  background-color: transparent !important;
}
.flatpickr-calendar .flatpickr-weekday {
  font-size: 100%;
}
.flatpickr-calendar .numInputWrapper {
  width: 60px !important;
  margin-right: px;
}
.flatpickr-calendar .numInputWrapper .arrowUp,
.flatpickr-calendar .numInputWrapper .arrowDown {
  border: none !important;
}
.flatpickr-calendar .numInputWrapper .arrowUp:hover,
.flatpickr-calendar .numInputWrapper .arrowUp *:hover,
.flatpickr-calendar .numInputWrapper .arrowDown:hover,
.flatpickr-calendar .numInputWrapper .arrowDown *:hover {
  color: #424242 !important;
}
.flatpickr-calendar .numInputWrapper .arrowUp:after,
.flatpickr-calendar .numInputWrapper .arrowDown:after {
  border-bottom-color: #616161 !important;
  border-top-color: #616161 !important;
}
.flatpickr-calendar .flatpickr-time {
  border: none !important;
}
.flatpickr-calendar .flatpickr-months {
  padding-bottom: 8px;
}
.flatpickr-calendar .flatpickr-current-month > * {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  border-radius: 4px;
}
.flatpickr-calendar .flatpickr-current-month > *:hover {
  color: #1976d2 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2) !important;
}
.flatpickr-calendar .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-next-month {
  margin: 16px !important;
}
.flatpickr-calendar .flatpickr-prev-month:hover .material-icons,
.flatpickr-calendar .flatpickr-next-month:hover .material-icons {
  color: #1976d2 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2) !important;
}

input[type=color] {
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid red;
}

html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
article,
section,
dialog,
nav,
main,
aside,
header,
caption,
mark {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  background: unset;
  position: static;
  top: unset;
  left: unset;
  width: unset;
  height: unset;
  bottom: unset;
  right: unset;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  background-color: #f5f5f5;
  overflow: hidden;
}

div {
  -webkit-tap-highlight-color: transparent;
}

textarea {
  border-radius: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  outline: 0;
}
textarea:focus {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
  outline: 0;
  border-width: 2px;
  padding: 7px 15px;
}

.mobile body {
  width: 100%;
  height: 100%;
}

/* Adapt some commonly used tags. */
a,
a:visited {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  -webkit-touch-callout: none !important;
}

a.action,
.action {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  font-weight: 600 !important;
  cursor: pointer;
  white-space: nowrap;
}

a.action-no-font {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.white-space--nowrap {
  white-space: nowrap;
}

input {
  background-color: transparent;
}

.p--1 {
  padding: 16px;
}

.p--1-5 {
  padding: 24px;
}

.p--2 {
  padding: 32px;
}

.pt--1 {
  padding-top: 16px;
}

.pb--1 {
  padding-bottom: 16px;
}

.pt--2 {
  padding-top: 32px;
}

.pb--2 {
  padding-bottom: 32px;
}

.pl--1 {
  padding-left: 16px;
}

.pr--1 {
  padding-right: 16px;
}

.pl--2 {
  padding-left: 32px;
}

.pr--2 {
  padding-right: 32px;
}

.m--0-5 {
  margin: 8px;
}

.m--1 {
  margin: 16px;
}

.m--2 {
  margin: 32px;
}

.mt--0-5 {
  margin-top: 8px;
}

.mt--1 {
  margin-top: 16px;
}

.mt--1-5 {
  margin-top: 24px;
}

.mb--1 {
  margin-bottom: 16px;
}

.mb--1-5 {
  margin-bottom: 24px;
}

.mt--2 {
  margin-top: 32px;
}

.mb--2 {
  margin-bottom: 32px;
}

.ml--0-5 {
  margin-left: 8px;
}

.ml--1 {
  margin-left: 16px;
}

.mr--0-5 {
  margin-right: 8px;
}

.mr--1 {
  margin-right: 16px;
}

.ml--n1 {
  margin-left: -16px;
}

.ml--2 {
  margin-left: 32px;
}

.mr--2 {
  margin-right: 32px;
}

.mr--4 {
  margin-right: 64px;
}

.gap--0-5 {
  gap: 8px;
}

.gap--1 {
  gap: 16px;
}

.gap--2 {
  gap: 32px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap--wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink--0 {
  flex-shrink: 0;
}

.align-items--center {
  align-items: center;
}

.justify-content--center {
  justify-content: center;
}

.text-align--right {
  text-align: right;
}

.text-align--center {
  text-align: center;
}

.float--left {
  float: left;
}

.font-overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
}

.font-caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.font-body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.font-caption--light {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.6 !important;
}

.font-caption--very-light {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.72 !important;
}

.cursor--zoom-in {
  cursor: zoom-in;
}

.cursor--default {
  cursor: default;
}

.width--full {
  width: 100%;
}

.height--full {
  height: 100%;
}

.overflow--hidden {
  overflow: hidden;
}

.overflow--scroll {
  overflow: scroll;
}

.overflow--auto {
  overflow: auto;
  scrollbar-gutter: stable;
}

.overflow--ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.opacity--6 {
  opacity: 0.6;
}

.opacity--8 {
  opacity: 0.8;
}

.p--1 {
  padding: 16px;
}

.z-index--above-all {
  z-index: 1998 !important;
}

a.no-outline, a.no-outline:focus, a.no-outline:hover, a.no-outline:active {
  outline: 0;
}

.error {
  color: #e53935;
}

.danger {
  color: #f44336;
}

.warn-bg {
  background-color: #fff9c4;
}

.error-bg {
  background-color: #ffcdd2;
}

.container-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.disable-all-animations *,
.disable-all-animations *:before,
.disable-all-animations *:after {
  animation: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
}

:root {
  --scrollbar-thumb-color: #bdbdbd;
  --scrollbar-thumb-color-hover: #616161;
  --scrollbar-track-color: rgba(255, 255, 255, 0.4);
  --scrollbar-track-color-hover: rgba(0, 0, 0, 0.075);
  --scrollbar-width: 10px;
}

.desktop {
  /* Firefox
     We have to use the `@supports` barrier, because if `scrollbar-color` is set
     all `-webkit-scrollbar` properties are ignored. So this would mess up the style
     for webkit browsers.
  */
  /* All other browsers. */
}
@supports not selector(::-webkit-scrollbar) {
  .desktop * {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
  }
  .desktop *:hover {
    scrollbar-color: var(--scrollbar-thumb-color-hover) var(--scrollbar-track-color-hover);
  }
}
.desktop *::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}
.desktop *::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: var(--scrollbar-width);
}
.desktop *:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color-hover);
}
.desktop *::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
  border-radius: var(--scrollbar-width);
}
.desktop *:hover::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color-hover);
}

.desktop .app__snackbar {
  transform: translate(16px, -16px);
}

.mobile .app__snackbar {
  transition: transform 175ms;
  transform: translateY(0);
}
.mobile .app__snackbar--with-bottom-app-bar {
  transform: translateY(calc(-1 * var(--bottom-app-bar-height) - 8px));
}

.cling-pro-teaser {
  max-width: 500px;
}
.cling-pro-teaser__quota-overview {
  margin: 32px 32px 0;
}
.cling-pro-teaser__quota-overview .quota-overview__tip {
  text-align: center;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.highlight-badge {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: inline-block;
  padding: 0 4px;
  font-size: 0.65rem;
  height: 20px;
  width: 32px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}
.highlight-badge--pro {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-secondary, rgba(255, 255, 255, 0.87));
}
.highlight-badge--new {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
}
.highlight-badge--margin-left {
  margin-left: 16px;
}
.highlight-badge--overlay {
  position: absolute;
  z-index: 1;
}

.principal-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.principal-info__complete-fullname {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
}
.principal-info__teams {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.72 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -6px;
}
.principal-info__full-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pro-feature-teaser-dialog {
  z-index: 1002 !important;
}
.pro-feature-teaser-dialog__description {
  margin-bottom: 32px;
}

.quota-limit-exceeded-dialog__quota-overview {
  margin: 32px 32px 0;
}
.quota-limit-exceeded-dialog__quota-overview .quota-overview__tip {
  text-align: center;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.board-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -9999;
  transition: background-image 175ms, background-color 175ms;
}

.board-chrome__before-after-children {
  top: 32px;
  z-index: 1998;
}
.mobile .board-chrome__before-after-children {
  top: 50vh;
}

.board-column {
  position: relative;
}
.desktop .board-column {
  min-height: calc(100% - 20px);
  padding-top: 16px;
}

.desktop .board-column--aux {
  min-height: calc(100% - 120px);
}

.mobile .board-column {
  padding-top: 60px;
}

.board-column--aux {
  margin-left: 32px;
  margin-right: 32px;
}
.board-column .board__aux-title {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 20px;
  margin-bottom: 14px;
  margin-right: 0;
}
.board-column.board-column__inbox {
  transition: opacity 525ms;
  opacity: 0.2;
}
.board-column.board-column__inbox.board-column__inbox--full {
  opacity: 1;
}
.board-column.board-column__inbox.board-column__inbox--empty:not(.board-column__inbox--full) {
  opacity: 0;
}
.board-column.board-column__inbox .board-column__inbox-title {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
  padding-left: 16px;
  padding-right: 8px;
  margin-top: 18px;
  margin-bottom: 14px;
  margin-right: 0;
  color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.board-column.board-column__inbox .board-column__inbox-title .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board-column.board-column__inbox .board-column__inbox-title .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.board-column.board-column__inbox .board-column__inbox-title .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board-column.board-column__inbox .board-column__inbox-title-badge {
  right: 1px;
  margin-top: 1px;
}

.board__aux-title {
  padding-left: 32px;
  margin-top: 28px;
  margin-bottom: 0;
  margin-right: 32px;
}
.board__aux-title-row-one {
  padding-left: 8px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
  color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.board__aux-title-row-one .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board__aux-title-row-one .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.board__aux-title-row-one .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board__aux-title-row-two {
  margin-top: 16px;
  width: 100%;
}
.board__aux-title-close {
  top: -8px;
  right: 16px;
}
.board__new-elm {
  margin-top: 16px;
}
.board__column-spacer {
  margin-top: 16px;
  height: 180px;
  min-height: 180px;
}

.clipboard_trashcan,
.inbox {
  display: flex;
  flex-direction: column;
}
.clipboard_trashcan > .inbox__help,
.inbox > .inbox__help {
  transform: translateY(-70px);
}
.clipboard_trashcan > .clipboard_trashcan__help,
.inbox > .clipboard_trashcan__help {
  transform: translateY(-118px);
}

@media (max-width: 599px) {
  body .mdc-drawer {
    max-width: 90vw;
    min-width: 70vw;
  }
}
@media (min-width: 600px) {
  body .mdc-drawer {
    min-width: 380px;
    max-width: 80vw;
  }
}
body .mdc-drawer__header {
  padding: 16px;
}
body .mdc-drawer__content {
  position: relative;
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.board-drawer__header {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 32px 0;
}
.board-drawer__header-icons .material-icons, .board-drawer__header-title, .board-drawer__header-user {
  border-radius: 10px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.86);
}
.board-drawer__header-back {
  outline: none;
}
.board-drawer__header-spacer {
  flex: 1;
}
.board-drawer__header-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
}
.board-drawer__header-avatar {
  flex-shrink: 0;
}
.board-drawer__header-user {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.board-drawer__header-username {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 16px;
}
.board-drawer__header-email {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.board-drawer__header-icons {
  display: flex;
  flex-direction: row;
}
.board-drawer__header-icons a {
  border: 0;
}
.board-drawer__header-icons .material-icons {
  cursor: pointer;
  margin-right: 16px;
}
.board-drawer__quota-overview {
  padding: 0 32px 16px;
  background-color: #fafafa;
  margin-top: -8px;
  border-bottom: 1px solid #e0e0e0;
}
.board-drawer .mdc-drawer__content {
  display: flex;
  flex-direction: column;
}
.board-drawer__version {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  color: #9e9e9e;
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding: 16px 32px;
}

.board-history {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  z-index: 998;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.board-history.board-history--open {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.board-history__list {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  padding: 0 16px;
}
.board-history__list .mdc-list-item {
  height: auto;
  min-height: unset;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
}
.board-history__list .mdc-list-item__graphic {
  margin-top: 4px;
}
.board-history__list-bottom-spacer {
  height: 80px !important;
}
.board-history__entry-header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.board-history__entry-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.board-history__entry-synopsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  font-style: italic;
  height: 20px;
}
.board-history__entry-synopsis:empty {
  height: 0;
}
.board-history__avatar {
  position: relative;
}
.board-history__avatar-badge {
  top: 1px;
  right: -4px;
}
.board-history__avatar-loading {
  position: absolute;
  margin-top: 4px;
}
.board-history__avatar-pi {
  opacity: 1;
  transition: opacity 175ms 200ms;
}
.board-history__avatar-pi--loading {
  transition: opacity 1s 1s;
  opacity: 0;
}
.board-history__header {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  padding: 8px 16px;
  height: var(--top-app-bar-height);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.board-history__header .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board-history__header .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.board-history__header .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.board-history__header-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
  flex: 1;
  padding: 0 16px;
}

.board-history-mobile {
  position: fixed;
  width: 100%;
  transition: transform 175ms;
  top: 0;
  bottom: env(safe-area-inset-bottom);
  transform: translateY(calc(100% + var(--top-app-bar-height) + env(safe-area-inset-bottom)));
}
.board-history-mobile.board-history--app-bar-shown {
  top: var(--top-app-bar-height);
  bottom: calc(var(--bottom-app-bar-height) + env(safe-area-inset-bottom));
}
.board-history-mobile.board-history--open {
  transform: translateY(0);
}
.board-history-mobile.board-history--collapsed {
  transform: translateY(calc(100% - var(--bottom-app-bar-height))) !important;
}
.board-history-mobile.board-history--collapsed .board-history__list {
  opacity: 0;
}
.board-history-mobile.board-history--collapsed .board-history__header {
  height: unset;
  padding-top: 6px;
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--bottom-app-bar-height));
}
.board-history-mobile .board-history__header {
  padding-left: 8px;
}

.board-history-desktop {
  transition: transform 175ms, width 175ms;
  width: 0;
  height: 100%;
  transform: translateX(306px);
}
.board-history-desktop.board-history--open {
  transform: translateX(0);
  width: 305px;
  min-width: 305px;
}

.board-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  user-select: none;
}
.board-title__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.board-title__archived {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.6 !important;
  margin-right: 16px;
}
.board-title__people-board {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.board-title__people-board-avatar {
  margin-right: 16px;
}
.board-title__people-board-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
}
.desktop .board-title > * {
  cursor: text;
}

.calendar-view {
  height: 100%;
  padding: 32px 16px 180px 32px;
  position: relative;
  max-width: 868px;
}
.mobile .calendar-view {
  height: 100vh;
  overflow: scroll;
  padding: 78px 4px calc(180px + env(safe-area-inset-bottom));
}

.calendar-view-card-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.calendar-view-card-preview__container {
  padding: 8px 8px 16px 8px;
}
.calendar-view-card-preview > *:last-child {
  flex: 1;
}
.calendar-view-card-preview__toggle {
  padding-top: 11px;
  margin-left: -2px;
}
.calendar-view-card-preview__content {
  --preview-time-margin-left: 0px;
  --synopsis-text-indent-time: 0px;
  --synopsis-text-indent-done: 0px;
  border-radius: 10px;
  flex: 1;
  padding: 8px 24px;
  position: relative;
}
.calendar-view-card-preview__content:hover {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.calendar-view-card-preview__content--with-time {
  --synopsis-text-indent-time: 46px;
}
.calendar-view-card-preview__content--with-time-wide {
  --synopsis-text-indent-time: 68px;
}
.calendar-view-card-preview__content--with-done-icon {
  --synopsis-text-indent-done: 28px;
  --preview-time-margin-left: 30px;
}
.calendar-view-card-preview__synopsis {
  cursor: pointer;
  text-indent: calc(var(--synopsis-text-indent-time) + var(--synopsis-text-indent-done));
}
.calendar-view-card-preview__synopsis,
.calendar-view-card-preview__synopsis > * {
  word-break: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.calendar-view-card-preview__done-icon {
  position: absolute;
  cursor: pointer;
  margin-top: -1px;
}
.calendar-view-card-preview__time {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-left: var(--preview-time-margin-left);
  cursor: pointer;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-top: 1px;
  padding: 0 4px;
  position: absolute;
}
.calendar-view-card-view {
  position: relative;
  margin-top: -16px;
  padding: 8px 8px 16px 8px;
  overflow: hidden;
}
.calendar-view-card-view .card-menu-desktop {
  right: 8px;
}
.calendar-view__loading, .calendar-view__hint, .calendar-view__more {
  border-radius: 16px;
  margin: 0 16px 16px 16px;
  background-color: rgba(255, 255, 255, 0.86);
  padding: 16px 24px;
}
.calendar-view__more {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  margin-top: 32px;
}
.calendar-view__hint {
  padding-top: 24px;
  margin-bottom: 32px;
}
.calendar-view__month-mood, .calendar-view__overdue, .calendar-view__hurray {
  background-color: rgba(255, 255, 255, 0.86);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 20%;
  width: 100%;
  height: 130px;
  padding-top: 8px;
  padding-left: 8px;
  display: flex;
}
.desktop .calendar-view__month-mood, .desktop .calendar-view__overdue, .desktop .calendar-view__hurray {
  height: 150px;
}

.calendar-view__month-mood-text, .calendar-view__overdue-text, .calendar-view__hurray-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.86);
  align-self: baseline;
}
.calendar-view__hurray {
  background-position: center 30%;
  background-image: url(/c/static/stock/hurray.svg);
}
.calendar-view__overdue {
  background-position: center 60%;
  background-image: url(/c/static/stock/late.svg);
}
.calendar-view-unscheduled {
  width: 100%;
}
.calendar-view-schedule-row {
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.86);
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
  align-items: flex-start;
  overflow: hidden;
}
.calendar-view-schedule-row__date {
  display: flex;
  align-items: center;
  padding: 16px 16px 0 24px;
  width: 130px;
}
.calendar-view-schedule-row__date > *:first-child {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  font-weight: 700;
  display: table-cell;
  vertical-align: middle;
  width: 34px;
  height: 34px;
  line-height: 34px;
  margin-right: 2px;
}
.calendar-view-schedule-row__date > *:nth-child(2) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-top: 2px;
  font-weight: 600;
}
.calendar-view-schedule-row--overdue {
  background-color: rgba(255, 205, 210, 0.86);
}
.calendar-view-schedule-row--after-overdue {
  margin-top: 32px;
}
.calendar-view-schedule-row--current-date .calendar-view-schedule-row__date > *:first-child {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-on-primary, rgba(255, 255, 255, 0.87));
  text-align: center;
  margin-left: -6px;
  margin-right: 8px;
  border-radius: 50%;
}
.calendar-view-schedule-row__cards {
  overflow: hidden;
  flex: 1;
  padding: 8px 16px 8px 16px;
  padding-bottom: 0;
  min-width: 300px;
}

.desktop-board-chooser {
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
  width: 0;
  min-width: 0;
  opacity: 0;
  margin-top: calc(-1 * var(--top-app-bar-height));
  height: calc(100% + var(--top-app-bar-height));
  transition: opacity 350ms, width 350ms, min-width 350ms;
  padding-bottom: 19px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  border-left: none;
}
.desktop-board-chooser .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.desktop-board-chooser .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.desktop-board-chooser .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.desktop-board-chooser .mdc-list-item--selected:before {
  opacity: 0.32 !important;
}
.desktop-board-chooser .mdc-list-item--selected * {
  color: #90caf9 !important;
}
.desktop-board-chooser .suggestion-textfield--selected::before {
  opacity: 0.2 !important;
  background-color: #fafafa !important;
}
.desktop-board-chooser .mdc-button:not(.mdc-button--raised) {
  border-color: #1e88e5 !important;
}
.desktop-board-chooser .mdc-button:not(.mdc-button--raised):hover {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: #1565c0 !important;
}
.desktop-board-chooser .mdc-button:not(.mdc-button--raised):hover .mdc-button__label,
.desktop-board-chooser .mdc-button:not(.mdc-button--raised):hover .mdc-button__icon {
  color: white !important;
}
.desktop-board-chooser .mdc-button:not(.mdc-button--raised) .mdc-button__label,
.desktop-board-chooser .mdc-button:not(.mdc-button--raised) .mdc-button__icon {
  color: #64b5f6 !important;
}
.desktop-board-chooser .mdc-text-field--outlined *,
.desktop-board-chooser .mdc-select--outlined * {
  border-color: #9e9e9e !important;
}
.desktop-board-chooser .mdc-text-field--outlined * .mdc-floating-label:not(.mdc-floating-label--float-above),
.desktop-board-chooser .mdc-select--outlined * .mdc-floating-label:not(.mdc-floating-label--float-above) {
  color: #9e9e9e !important;
}
.desktop-board-chooser .mdc-select__dropdown-icon {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23cccccc%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") !important;
}
.desktop-board-chooser *:not(.mdc-theme--primary),
.desktop-board-chooser .mdc-list-item {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}
.desktop-board-chooser .mdc-button.mdc-button--raised:disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.desktop-board-chooser .mdc-button.mdc-button--raised:disabled > .mdc-button__label {
  color: rgba(255, 255, 255, 0.32) !important;
}
@supports (backdrop-filter: blur(50px)) {
  .desktop-board-chooser {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(50px);
  }
}
@supports (-webkit-backdrop-filter: blur(50px)) {
  .desktop-board-chooser {
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(50px);
  }
}
.desktop-board-chooser--open {
  opacity: 1;
  width: 305px;
  min-width: 305px;
}
.desktop-board-chooser__top-buttons {
  margin-top: 16px;
  justify-content: flex-end;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
}
.desktop-board-chooser__tab-bar {
  margin-top: 24px;
}
.desktop-board-chooser__tab-bar .mdc-tab-scroller__scroll-content {
  justify-content: space-evenly;
}
.desktop-board-chooser__tab {
  position: relative;
}
.desktop-board-chooser__badge {
  right: -12px;
  top: -2px;
}
.desktop-board-chooser__add-board {
  width: 100%;
  margin: 8px 0;
  padding: 0 32px;
  display: flex;
  justify-content: center;
}
.desktop-board-chooser__list {
  flex: 1;
  margin: 0;
  margin-top: 24px;
}
.desktop-board-chooser__list .searchable-list__middle {
  margin-right: 12px;
  margin-left: 12px;
}
.desktop-board-chooser .mdcx-list-divider--with-title {
  border: 0 !important;
  margin: 32px 0 0 !important;
  height: 32px !important;
}
.desktop-board-chooser .mdcx-list-divider--with-title .mdcx-list-divider__title {
  height: inherit !important;
  background-color: inherit !important;
}
.desktop-board-chooser__try-cling-pro {
  margin-top: 16px;
  margin-bottom: -19px;
  padding: 24px 16px;
  background: #212121;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.scroll-indicator__container--desktop {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
@media (max-width: 1150px) {
  .public .scroll-indicator__container--desktop {
    bottom: 64px;
  }
}

.scroll-indicator__overview {
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  list-style: none;
  z-index: 1;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 36px;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-button:hover {
  color: #bbdefb !important;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-item {
  border-radius: 10px;
  border-radius: 50%;
  flex: 1;
  display: block;
  height: 16px;
  width: 16px;
  margin: 0 6px;
  position: relative;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-item--visible {
  background-color: #e0e0e0 !important;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-item--not-visible {
  background-color: #757575 !important;
  cursor: pointer;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-item--not-visible:hover {
  background-color: #bbdefb !important;
}
.scroll-indicator__overview.scroll-indicator__overview--desktop .scroll-indicator__overview-item--has-updates:before {
  background-color: #d81b60 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60) !important;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 0;
  content: "";
}
.scroll-indicator__overview.scroll-indicator__overview--mobile {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  top: -2px;
  position: absolute;
}
.scroll-indicator__overview.scroll-indicator__overview--mobile .scroll-indicator__overview-item {
  flex: 1;
  display: block;
  height: 6px;
  border: 1px solid #212121;
  position: relative;
}
.scroll-indicator__overview.scroll-indicator__overview--mobile .scroll-indicator__overview-item--visible {
  background-color: #2196f3 !important;
}
.scroll-indicator__overview.scroll-indicator__overview--mobile .scroll-indicator__overview-item--not-visible {
  background-color: #616161 !important;
}
.scroll-indicator__overview.scroll-indicator__overview--mobile .scroll-indicator__overview-item--has-updates:before {
  position: absolute;
  width: 12px;
  height: 4px;
  border-left: 1px solid #212121;
  right: 0;
  top: 0;
  content: "";
  background-color: #d81b60 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60) !important;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.toolbar-items {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.toolbar-items__top-right, .toolbar-items__top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.desktop .toolbar-items__top-right, .desktop .toolbar-items__top-left {
  border-radius: 16px;
  height: 36px;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding-right: 12px;
  padding-left: 12px;
}

.desktop .toolbar-items__top-right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 184px;
}

.desktop .toolbar-items__top-right {
  justify-content: flex-end;
}

.toolbar-items__typography-logo {
  background-image: url("/c/static/cling_typography_white_36pt.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  opacity: 0.9;
  width: 64px;
  height: 36pt;
  margin-right: 32px;
}
.toolbar-items__typography-logo--small {
  background-image: url("/c/static/cling_typography_white_18pt.svg");
  height: 18px;
  width: 44px;
  margin-right: 8px;
  margin-top: 3px;
  margin-right: 16px;
}
.toolbar-items__flex {
  flex: 1;
}
.toolbar-items__link {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  cursor: pointer;
}
.toolbar-items__link:hover {
  text-decoration: underline;
}
.desktop .toolbar-items--public {
  padding-left: 8px;
}

.mobile .toolbar-items--public.toolbar-items--bottom {
  gap: 16px;
  padding-left: 16px;
}

.toolbar-items--public-menu-button {
  height: var(--app-bar-button-height) !important;
}
.desktop .toolbar-items--public .toolbar-items__cta {
  margin: 0 12px;
  border-color: #fafafa !important;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}
.desktop .toolbar-items--public .toolbar-items__cta:hover {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.desktop .toolbar-items--public .toolbar-items__app-bar-button .mdc-button:not(.mdc-button--raised) .mdc-button__label,
.desktop .toolbar-items--public .toolbar-items__app-bar-button .mdc-button:not(.mdc-button--raised) .material-icons, .desktop .toolbar-items--public .toolbar-items__app-bar-button.mdc-button:not(.mdc-button--raised) .mdc-button__label,
.desktop .toolbar-items--public .toolbar-items__app-bar-button.mdc-button:not(.mdc-button--raised) .material-icons {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}

.toolbar-items--public .toolbar-items__app-bar-button {
  margin: 0;
}
.desktop .toolbar-items--public .toolbar-items__app-bar-button .mdc-button:hover {
  background-color: #fafafa !important;
}
.desktop .toolbar-items--public .toolbar-items__app-bar-button .mdc-button:hover .mdc-button__label {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.toolbar-items--bottom {
  justify-content: space-between;
  padding: 0 16px;
}
.mobile .toolbar-items--top {
  justify-content: flex-end;
  margin-right: -8px;
}
.mobile .toolbar-items--top .toolbar-items__search {
  width: 42px;
}

.desktop .toolbar-items__title {
  padding: 0;
}
.desktop .toolbar-items__title .board-title__text {
  border-radius: 16px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 2px 18px;
  background-color: rgba(0, 0, 0, 0.6);
}

.desktop .toolbar-items__title--left {
  justify-content: flex-start !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.mobile .toolbar-items__title--left {
  padding-left: 16px !important;
}

.toolbar-items__dangerous .material-icons:not(:disabled), .toolbar-items__dangerous {
  color: #e53935 !important;
}
.toolbar-items__search {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
  flex: 1;
  margin-right: 16px;
  z-index: 1;
}
.desktop .toolbar-items__search .search-box {
  margin-left: -36px;
}

.mobile .toolbar-items__search {
  margin-right: 0;
}
.mobile .toolbar-items__search .search-box__row {
  margin-left: -4px;
}
.mobile .toolbar-items__search .search-box__options {
  top: var(--top-app-bar-height);
  margin-top: 0;
  left: 0;
  right: 0;
  width: unset !important;
  border-bottom: 1px solid #616161;
}

.toolbar-items__search-open-placeholder--desktop {
  width: 32px;
}
@media (max-width: 599px) {
  .mobile .board-chooser__menu__surface {
    left: 0 !important;
    min-width: 100vw !important;
    width: 100vw !important;
  }
}
.toolbar-items-public-bottom-desktop-right {
  border-radius: 16px 0 0 16px;
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  position: absolute;
  right: 0;
  bottom: 16px;
  padding-left: 16px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toolbar-items__try-cling-pro {
  border-radius: 10px;
  margin: 32px 8px;
  padding: 16px 16px 24px;
  border-bottom: 1px solid #616161;
  background-color: #f5f5f5;
}

.board-menu-item__direct-link {
  background-color: #fafafa;
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 16px 32px 32px;
}
.board-menu-item__direct-link-caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.board-menu-item__direct-link-form {
  display: flex;
  flex-direction: row;
  border: 1px solid #bdbdbd;
  background-color: #fafafa;
  border-radius: 10px;
}
.board-menu-item__direct-link-form input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  border: none;
  line-height: 1rem;
  outline: none;
  flex: 1;
  padding: 0 4px;
  margin-right: 8px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.card {
  --card-vert-gap: 16px;
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  overflow: hidden;
  overflow: hidden;
  margin-top: var(--card-vert-gap);
  margin-left: 16px;
}
.card.card--menu-shown {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.card.card--menu-shown .card__children {
  position: relative;
}
.desktop .card.card--menu-shown {
  border-top-right-radius: 0;
}

.card__mdc-card--with-children,
.card__mdc-card--with-children .card__content {
  border-bottom-right-radius: 4px !important;
}
.card .mdc-card {
  box-shadow: none !important;
  border-radius: 16px;
  position: relative;
}
.card .mdc-card.card--search-match mark, .card .mdc-card.card--new-or-changed mark, .card .mdc-card.card--highlighted mark {
  background-color: #fdd835 !important;
}
.card .mdc-card.card--search-match:before, .card .mdc-card.card--new-or-changed:before, .card .mdc-card.card--highlighted:before {
  background-color: #fdd835;
  position: absolute;
  width: 14px;
  top: 0;
  bottom: 0;
  content: "";
  border-right: 1px solid #fafafa;
  z-index: 1;
}
.card .mdc-card.card--new-or-changed:before {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}
.card .mdc-card.card--highlighted:before {
  animation: card-highlight 525ms;
  animation-iteration-count: 4;
  animation-timing-function: ease-in;
}
.board-chrome--board-history .card .mdc-card.card--highlighted:before {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
}

@keyframes card-highlight {
  80% {
    background-color: transparent;
    border-right-color: transparent;
  }
}
.card--archived .card__children {
  opacity: 0.5;
}
.card__content {
  border-radius: 16px;
  overflow: hidden;
}
.card__children {
  display: flex;
  flex-direction: column;
}
.card__new-or-changed-badge {
  margin-top: 1px !important;
  margin-right: 1px !important;
  right: 0;
  z-index: 1000 !important;
}
.card:not(.card--level-1):not(.card--level-2),
.card:not(.card--level-1):not(.card--level-2) .mdc-card,
.card:not(.card--level-1):not(.card--level-2) .card__content {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.card--level-1, .card--level-2 {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-left: 0;
}
.card__comment-link .action {
  position: relative;
  padding-right: 16px;
}
.card__comment-link > *:last-child {
  flex: 1;
}
.card__bottom-space {
  margin-top: var(--card-vert-gap);
}
.mobile .card--no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card--color-0 {
  background-image: linear-gradient(45deg, #ffffff, white);
}
.card--color-0.card {
  background-color: rgba(255, 255, 255, 0.42) !important;
  background-image: unset;
}

.card--color-1 {
  background-image: linear-gradient(45deg, #fff475, #fff79e);
}
.card--color-1.card {
  background-color: rgba(255, 244, 117, 0.42) !important;
  background-image: unset;
}

.card--color-2 {
  background-image: linear-gradient(45deg, #ffc84d, #ffd982);
}
.card--color-2.card {
  background-color: rgba(255, 200, 77, 0.42) !important;
  background-image: unset;
}

.card--color-3 {
  background-image: linear-gradient(45deg, #f9a4a4, #fbbfbf);
}
.card--color-3.card {
  background-color: rgba(249, 164, 164, 0.42) !important;
  background-image: unset;
}

.card--color-4 {
  background-image: linear-gradient(45deg, #fdcfe8, #feddef);
}
.card--color-4.card {
  background-color: rgba(253, 207, 232, 0.42) !important;
  background-image: unset;
}

.card--color-5 {
  background-image: linear-gradient(45deg, #d7aefb, #e3c6fc);
}
.card--color-5.card {
  background-color: rgba(215, 174, 251, 0.42) !important;
  background-image: unset;
}

.card--color-6 {
  background-image: linear-gradient(45deg, #aecbfa, #c6dbfc);
}
.card--color-6.card {
  background-color: rgba(174, 203, 250, 0.42) !important;
  background-image: unset;
}

.card--color-7 {
  background-image: linear-gradient(45deg, #a4effb, #bff4fc);
}
.card--color-7.card {
  background-color: rgba(164, 239, 251, 0.42) !important;
  background-image: unset;
}

.card--color-8 {
  background-image: linear-gradient(45deg, #bafda9, #cffec3);
}
.card--color-8.card {
  background-color: rgba(186, 253, 169, 0.42) !important;
  background-image: unset;
}

.card--color-9 {
  background-image: linear-gradient(45deg, #e6c9a8, #eed9c2);
}
.card--color-9.card {
  background-color: rgba(230, 201, 168, 0.42) !important;
  background-image: unset;
}

.card--color-10 {
  background-image: linear-gradient(45deg, #e8eaed, #eff0f2);
}
.card--color-10.card {
  background-color: rgba(232, 234, 237, 0.42) !important;
  background-image: unset;
}

.card-one-line-aux {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  background-color: rgba(0, 0, 0, 0.03);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid transparent;
}
.card-one-line-aux--top {
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.card-one-line-aux--no-icon {
  margin-left: 0;
}
.card-one-line-aux > .action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-one-line-aux--with-action .card__new-or-changed-badge {
  margin-top: 0 !important;
}
.card-one-line-aux--with-action .action {
  position: relative;
  padding-right: 18px;
}
.card-one-line-aux--with-action > *:last-child {
  flex: 1;
}

.card-aux {
  background-color: rgba(0, 0, 0, 0.03);
}
.card-aux--below {
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}

body.dnd--dragging .card--chained-with-prev {
  max-height: 4px !important;
  overflow-y: hidden;
}

.card-details {
  overflow-y: hidden;
  min-height: 32px;
  display: flex;
  flex-direction: column;
}

.card-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
  min-height: 64px;
  max-height: 64px;
}
.mobile .card-with-icon {
  padding-right: 32px;
}

.card-with-icon__icon-outline {
  border-radius: 16px 10px 10px 16px;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.03));
  overflow: hidden;
  border: 1px solid white;
  min-height: 64px;
  min-width: 64px;
  display: flex;
  justify-content: center;
}
.card-with-icon__icon {
  cursor: default;
  min-height: 62px;
  min-width: 62px;
  opacity: 0.5;
  background-size: 48px;
  background-repeat: no-repeat;
  background-position: center;
}
.card-with-icon__play {
  background-repeat: no-repeat;
  background-position: center;
  cursor: zoom-in !important;
  background-size: 32px !important;
  min-width: 62px;
  min-height: 62px;
  opacity: 1;
}
.card-with-icon__icon-outline:hover .card-with-icon__play {
  animation: card-with-icon-heartbeat 2s;
}
@keyframes card-with-icon-heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.85);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.85);
  }
  80% {
    transform: scale(1);
  }
}

.card-with-icon__caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.6 !important;
  line-height: 1rem;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}
.card-with-icon__caption,
.card-with-icon__caption > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-with-icon__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-with-icon__content {
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.card-color-chooser {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20px);
  grid-row-gap: 8px;
  justify-content: space-between;
  padding: 8px 24px;
}
.card-color-chooser__color {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border: 1px solid white;
}
.card-color-chooser__color::before, .card-color-chooser__color::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.card-color-chooser__color::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.card-color-chooser__color.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.card-color-chooser__color.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.card-color-chooser__color.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.card-color-chooser__color.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.card-color-chooser__color.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.card-color-chooser__color::before, .card-color-chooser__color::after {
  background-color: #000;
}
.card-color-chooser__color:hover::before {
  opacity: 0.04;
}
.card-color-chooser__color.mdc-ripple-upgraded--background-focused::before, .card-color-chooser__color:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.card-color-chooser__color:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.card-color-chooser__color:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.card-color-chooser__color.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.card-color-chooser__color::before, .card-color-chooser__color::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.card-color-chooser__color.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.card-color-chooser__color.card--color-0 {
  background-color: #fff !important;
}
.card-color-chooser--selected {
  border: 1px solid #616161;
  z-index: 1;
}

.card-color-chooser-select__color {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.card-color-chooser-select__color.card--color-0 {
  background-color: #fff !important;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.card-editor {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 1998;
}
.card-editor:hover {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.card-editor__desktop {
  border-radius: 16px;
  max-height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: -2000px;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.card-editor__desktop .card-editor {
  display: flex;
  margin-left: 0;
  margin-top: 0;
  padding: 0;
}
.card-editor__desktop .card__content {
  flex: 1;
  overflow: hidder;
}
.card-editor__desktop .card__children,
.card-editor__desktop .card-details {
  height: 100%;
}
.card-editor__desktop .card,
.card-editor__desktop .card .mdc-card {
  width: 100%;
}
.card-editor__button-bar {
  display: flex;
  flex-direction: row;
  justify-items: end;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 16px 0 0 32px;
}
.card-editor__sub-editors {
  flex: 1;
}
.card-editor .card-editor__content {
  overflow-y: auto;
  scrollbar-gutter: stable;
  margin-right: calc(16px - var(--scrollbar-width)) !important;
  padding-right: calc(16px - var(--scrollbar-width)) !important;
}
.card-editor .card-details {
  margin: 24px 24px 0 24px;
}
.card-editor__fullscreen-backdrop, .card-editor__fullscreen-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.card-editor__fullscreen-backdrop .card-editor,
.card-editor__fullscreen-backdrop .card-editor:hover, .card-editor__fullscreen-content .card-editor,
.card-editor__fullscreen-content .card-editor:hover {
  box-shadow: none !important;
}
.card-editor__fullscreen-navbar .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-editor__fullscreen-navbar .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.card-editor__fullscreen-navbar .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-editor__fullscreen-navbar .mdc-list-item--selected:before {
  opacity: 0.32 !important;
}
.card-editor__fullscreen-navbar .mdc-list-item--selected * {
  color: #90caf9 !important;
}
.card-editor__fullscreen-navbar .suggestion-textfield--selected::before {
  opacity: 0.2 !important;
  background-color: #fafafa !important;
}
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised) {
  border-color: #1e88e5 !important;
}
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised):hover {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: #1565c0 !important;
}
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised):hover .mdc-button__label,
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised):hover .mdc-button__icon {
  color: white !important;
}
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised) .mdc-button__label,
.card-editor__fullscreen-navbar .mdc-button:not(.mdc-button--raised) .mdc-button__icon {
  color: #64b5f6 !important;
}
.card-editor__fullscreen-navbar .mdc-text-field--outlined *,
.card-editor__fullscreen-navbar .mdc-select--outlined * {
  border-color: #9e9e9e !important;
}
.card-editor__fullscreen-navbar .mdc-text-field--outlined * .mdc-floating-label:not(.mdc-floating-label--float-above),
.card-editor__fullscreen-navbar .mdc-select--outlined * .mdc-floating-label:not(.mdc-floating-label--float-above) {
  color: #9e9e9e !important;
}
.card-editor__fullscreen-navbar .mdc-select__dropdown-icon {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23cccccc%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") !important;
}
.card-editor__fullscreen-navbar *:not(.mdc-theme--primary),
.card-editor__fullscreen-navbar .mdc-list-item {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}
.card-editor__fullscreen-navbar .mdc-button.mdc-button--raised:disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.card-editor__fullscreen-navbar .mdc-button.mdc-button--raised:disabled > .mdc-button__label {
  color: rgba(255, 255, 255, 0.32) !important;
}
.card-editor__fullscreen-content {
  top: var(--top-app-bar-height) !important;
  bottom: env(safe-area-inset-bottom);
}
.card-editor__fullscreen-content .card,
.card-editor__fullscreen-content .mdc-card {
  border-radius: 0;
  height: 100%;
  margin: 0 !important;
}
.card-editor__fullscreen-content .card__content {
  flex: 1;
  overflow: hidden;
}
.card-editor__fullscreen-content .card__children,
.card-editor__fullscreen-content .card-details {
  height: 100%;
}
.ios--12-and-below .card-editor__fullscreen-content {
  bottom: 55%;
}
@media (min-height: 500px) {
  .ios--12-and-below .card-editor__fullscreen-content {
    bottom: 240px;
  }
}
@media (min-height: 600px) {
  .ios--12-and-below .card-editor__fullscreen-content {
    bottom: 320px;
  }
}

.card-editor__add-custom-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-editor__thumbnail {
  min-width: 100% !important;
  min-height: 100% !important;
  cursor: pointer;
}
.card-editor__types {
  padding: 8px 24px 8px 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  opacity: 0.8;
}
.card-editor__content {
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.card-menu__remove.material-icons:not(:disabled), .card-menu__remove {
  color: #e53935 !important;
}
.mobile .card-menu__remove.material-icons:not(:disabled), .mobile .card-menu__remove {
  color: #f44336 !important;
}

.card-menu-desktop {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  background-color: #424242;
  overflow: hidden;
  z-index: 2000;
  position: absolute;
  margin-top: -16px;
  right: 0;
}
.card-menu-desktop .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-menu-desktop .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.card-menu-desktop .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-menu-desktop__mdc-menu .mdc-list-item__meta {
  padding-left: 36px;
  padding-right: 12px;
}
.card-menu-desktop__content {
  padding: 3px 0 3px 4px;
  align-items: center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.card-menu-desktop__content .material-icons {
  height: 24px;
  width: 24px;
  padding: 2px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
  text-shadow: -1px 0 0 rgba(0, 0, 0, 0.3), 0 1px 1px rgba(0, 0, 0, 0.3), 1px 0 1px rgba(0, 0, 0, 0.3), 0 -1px 0 rgba(0, 0, 0, 0.3);
}

.card-menu-mobile {
  position: absolute;
  min-height: 128px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1998;
}
.card-menu-mobile__content {
  border-radius: 28px;
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  padding: 8px;
  background-color: rgba(40, 40, 40, 0.95);
}
.card-menu-mobile__content .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-menu-mobile__content .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.card-menu-mobile__content .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.card-menu-mobile__quick-items {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.card-menu-mobile__quick-items > * {
  flex: 0 1 25%;
  overflow: hidden;
  margin: 2px 0;
  min-width: 64px;
}
.card-menu-mobile__cancel {
  width: 100%;
  margin-top: 16px;
}
.card-menu-mobile__title {
  flex: 1;
  padding: 16px;
  padding-top: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #9e9e9e;
}
.card-menu-mobile__title-synopsis {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
}
.card-menu-mobile__open {
  justify-content: center;
  display: flex;
}
.card-menu-mobile__placeholder {
  position: absolute !important;
  right: 2px;
  margin-top: 14px;
  z-index: 2;
}
.card-menu-mobile__placeholder--on-image > *:after {
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.card-menu-info {
  max-width: 400px;
  margin-top: -8px;
  margin-bottom: -8px;
}
.desktop .card-menu-info {
  background-color: #fafafa;
  padding: 16px 32px 32px 32px;
}

.mobile .card-menu-info {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
  padding: 16px;
}

.card-menu-info__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
}
.card-menu-info__caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  padding-top: 8px;
}
.card-menu-info__content {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.card-menu-info__content > *:first-child {
  margin-right: 32px;
}
.card-menu-info__direct-link {
  display: flex;
  flex-direction: row;
  margin-right: -8px;
  border: 1px solid #bdbdbd;
  background-color: #fafafa;
  border-radius: 10px;
}
.card-menu-info__direct-link input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  border: none;
  line-height: 1rem;
  outline: none;
  flex: 1;
  padding: 0 4px;
  margin-right: 8px;
}
.card-menu-info__rel-date {
  white-space: nowrap;
}

.card-synopsis > * {
  display: none;
}
.card-synopsis > *:first-child {
  display: inherit;
  padding: 0;
  margin: 0;
}
.card-synopsis pre:first-child,
.card-synopsis code {
  font-family: unset;
  padding: 0;
  margin: 0;
}
.card-synopsis--one-liner {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-synopsis--one-liner > *:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 24px;
}

.cling-info-card {
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: #ad1457;
  padding: 16px 24px 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  border: 1px solid #eee;
  font-weight: 600;
}
.cling-info-card .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.cling-info-card .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.cling-info-card .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.cling-info-card--floating {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 32px;
}
.mobile .cling-info-card--floating {
  margin-top: calc(var(--top-app-bar-height) + 16px);
}

.cling-info-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: -16px;
}
.cling-info-card__title, .cling-info-card__content {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.cling-info-card__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
}
.cling-info-card__content {
  margin-top: 16px;
}
.cling-info-card__logo {
  background-image: url("/c/static/cling_typography_white_36pt.svg");
  width: 64px;
  height: 36pt;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  opacity: 0.9;
}
.cling-info-card__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cling-info-card__cta {
  color: white !important;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.condensed-thumbnail {
  flex-shrink: 0;
  min-width: 62px;
  min-height: 62px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  cursor: zoom-in;
  transition: background-image 175ms;
}
.condensed-thumbnail--contain {
  background-size: contain;
}
.condensed-thumbnail__loading {
  cursor: default;
  background-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.condensed-thumbnail__no-image {
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
  background-size: 48px;
  opacity: 0.5;
}

.error-card {
  color: red;
  background-color: lightpink;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.file-card {
  cursor: pointer;
}
.file-card__icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 51px;
  text-align: center;
  color: white;
  padding-top: 34px;
  font-size: 10px;
  letter-spacing: 0;
  overflow: hidden;
  text-transform: uppercase;
}

.file-card-editor {
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: 16px;
}

.pdf-viewer {
  background-color: #212121;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1998;
}
.pdf-viewer__close {
  position: absolute;
  z-index: 1998;
  margin-top: 4px;
  margin-left: 8px;
}
@media (prefers-color-scheme: dark) {
  .pdf-viewer__close .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
    color: rgba(255, 255, 255, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  }
  .pdf-viewer__close .material-icons:disabled {
    color: rgba(255, 255, 255, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
    opacity: 0.26 !important;
  }
  .pdf-viewer__close .material-icons.mdc-theme--primary {
    color: rgba(255, 255, 255, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  }
}
.pdf-viewer__iframe {
  background-color: #212121;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  z-index: 1;
}
.pdf-viewer--show {
  animation: pdf-viewer-show 350ms;
}
.pdf-viewer--hide {
  animation: pdf-viewer-hide 350ms;
}

@keyframes pdf-viewer-show {
  from {
    opacity: 0;
    display: block;
  }
  to {
    opacity: 1;
  }
}
@keyframes pdf-viewer-hide {
  to {
    opacity: 0;
    display: none;
  }
}
.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.image-card__play, .card-with-icon__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.image-card {
  position: relative;
  min-height: 64px;
  cursor: zoom-in;
  overflow: hidden;
}
.image-card.image-card--banner {
  max-height: 64px;
  min-height: 64px;
}
.image-card.image-card--cinema {
  background-color: #e0e0e0;
}
.image-card.image-card--cinema .image-card__play {
  background-size: 80px;
  width: 80px;
  height: 80px;
}
.image-card__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 175ms;
}
.image-card__portrait-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scale(2);
  filter: blur(2px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.7;
  transition: background-image 175ms;
}
.image-card__loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-card__play {
  background-repeat: no-repeat;
  background-position: center;
  cursor: zoom-in !important;
  background-size: 48px;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.image-card__play:hover {
  animation: image-card-heartbeat 2s;
}
@keyframes image-card-heartbeat {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  20% {
    transform: scale(0.85) translate(-50%, -50%);
  }
  40% {
    transform: scale(1) translate(-50%, -50%);
  }
  60% {
    transform: scale(0.85) translate(-50%, -50%);
  }
  80% {
    transform: scale(1) translate(-50%, -50%);
  }
}
.image-card__broken {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.image-card__broken-icon {
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 64px;
  opacity: 0.2;
}
.image-card--condensed {
  max-height: 64px;
  min-height: 64px;
}
.image-card--condensed .image-card__more {
  border-radius: 10px 0 0 0;
  z-index: 1;
  right: 0;
  padding: 8px 16px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.86);
  display: block;
}
.image-card--condensed > .image-card__title {
  -webkit-line-clamp: unset;
}
.image-card--condensed > .image-card__title, .image-card--condensed > .image-card__title * {
  display: block;
}
.image-card--condensed > .image-card__title > *, .image-card--condensed > .image-card__title > * * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.image-card__card-color-marker {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 22px;
  right: 24px;
  border: 1px solid #212121;
  border-radius: 100%;
}
.image-card__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  font-weight: 700;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  position: absolute;
  padding: 8px 16px;
  z-index: 3;
}
.image-card__title--banner {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
}
.image-card__title--banner > * {
  position: relative;
  white-space: nowrap;
  -webkit-text-stroke: 0.4px black;
  text-align: center;
}
.image-card__title--banner > *, .image-card__title--banner > * * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.image-card__title--cinema {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  margin: 8px;
  left: 0;
  top: 0;
  max-width: 80%;
  background-color: rgba(255, 255, 255, 0.88);
}
@supports (-webkit-backdrop-filter: blur(50px)) {
  .image-card__title--cinema {
    background-color: rgba(255, 255, 255, 0.58);
    -webkit-backdrop-filter: blur(50px);
  }
}
@supports (backdrop-filter: blur(50px)) {
  .image-card__title--cinema {
    background-color: rgba(255, 255, 255, 0.58);
    backdrop-filter: blur(50px);
  }
}
.image-card__title--cinema > * {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.image-card--banner .image-card__title--cinema {
  margin-top: 9px;
}
.image-card--banner .image-card__title--cinema > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.image-card__title--with-color.image-card__title--banner {
  left: 48px;
  right: 48px;
}

.image-card-preview {
  position: static;
}
.image-card-preview__img {
  background-color: black;
  background-size: contain;
}
.image-card-preview__badge {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  position: absolute;
  bottom: 80px;
  right: 32px;
  color: #e0e0e0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.pswp__ui {
  z-index: 2147483647;
}
.pswp__ui .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.pswp__ui .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.pswp__ui .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.pswp__ui div {
  position: absolute;
  top: 0;
  display: flex;
}
.pswp__ui_top_left, .pswp__ui_left {
  left: 0;
}
.pswp__ui_top_right, .pswp__ui_right {
  right: 0;
}
.pswp__ui_left, .pswp__ui_right {
  bottom: 0;
  flex-direction: column;
  justify-content: center;
}
.pswp__ui .material-icons {
  cursor: pointer;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.link-card__content {
  cursor: pointer;
}
.link-card__content-title {
  text-decoration: underline;
  text-decoration-color: #757575;
}
.link-card__mode {
  position: relative;
}
.link-card__mode-title {
  cursor: pointer;
}
.link-card__mode-image .image-card__title--banner {
  z-index: 1;
}
.link-card__mode-image .image-card__title--banner > * {
  margin-top: -4px;
}
.link-card__mode-image .image-card__title--banner > *::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #f5f5f5;
  z-index: -1;
}
.link-card__mode-image .image-card__title--cinema, .link-card__mode-image .image-card__title--cinema * {
  text-decoration: underline;
  text-decoration-color: #757575 !important;
}
.link-card__mode-image--banner,
.link-card__mode-image--banner * {
  cursor: pointer !important;
}
.link-card__mode-domain {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  position: absolute;
  right: 24px;
  bottom: 4px;
  color: white;
  text-shadow: 0 0 2px #000;
  -webkit-font-smoothing: antialiased;
}
.mobile .link-card__mode-domain {
  bottom: 2px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.new-elm {
  border-radius: 10px;
  background-color: #fafafa;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.new-elm:hover {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.new-elm__quick-add-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.new-elm-fab {
  position: fixed;
  bottom: 64px;
  cursor: pointer;
  right: 32px;
  transition: transform 262.5ms !important;
  display: flex;
  align-items: center;
}
.mobile .new-elm-fab {
  bottom: calc(var(--bottom-app-bar-height) + 16px + env(safe-area-inset-bottom));
}

.mobile .new-elm-fab--with-toast {
  bottom: calc(var(--bottom-app-bar-height) + 170px + env(safe-area-inset-bottom));
}

.new-elm-fab--shown {
  transform: translateY(0);
}
.new-elm-fab--hidden {
  transform: translateY(calc(var(--bottom-app-bar-height) + 16px + env(safe-area-inset-bottom) + 80px));
}
.new-elm-fab__button--add, .new-elm-fab__button--cancel {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: #d32f2f;
  border: 2px solid white !important;
  color: white !important;
  border-radius: 50%;
  font-size: 30px;
  width: 56px;
  height: 56px;
  padding: 10px;
}
.new-elm-fab__button--add:hover, .new-elm-fab__button--cancel:hover {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.new-elm-fab__button--cancel {
  background-color: #424242;
}
.new-elm-fab__button--paste {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: #d32f2f;
  color: white !important;
  border: 1px solid white !important;
  border-radius: 50%;
  padding: 5px;
  margin-right: 4px;
  transition: transform 262.5ms !important;
}
.new-elm-fab__button--paste:hover {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.new-elm-fab__button--paste-shown {
  transform: translateX(0);
}
.new-elm-fab__button--paste-hidden {
  transform: translateX(48px);
}
.new-elm-fab__help {
  background-color: #424242;
  color: white;
  border: 1px solid white;
  transition: opacity 262.5ms ease-in;
  overflow: hidden;
  height: 52px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  padding-left: 20px;
  padding-right: 36px;
  display: flex;
  align-items: center;
  transform: translateX(64px);
  max-width: 80vw;
}
.new-elm-fab__help .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.new-elm-fab__help .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.new-elm-fab__help .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.new-elm-fab__help--hidden {
  opacity: 0;
}
.new-elm-fab__help--shown {
  opacity: 1;
}
.new-elm-fab__help > div {
  display: flex;
  align-items: center;
}
.new-elm-fab__help > div > * {
  margin-right: 8px;
}
.new-elm-fab__help > div > span {
  white-space: nowrap;
}

.new-elm-fab-mini {
  position: absolute;
  z-index: 1998;
  display: flex;
  justify-content: center;
  margin-top: -24px;
  width: 100%;
  left: 0;
  right: 0;
}
.new-elm-fab-mini__button {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: #d32f2f;
  color: white !important;
  border: 1px solid white !important;
  border-radius: 50%;
  padding: 5px;
}
.new-elm-fab-mini__button:hover {
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.note-card {
  margin: 24px;
  margin-top: 12px;
  margin-bottom: 20px;
}
.mobile .note-card {
  margin-right: 32px;
}

.note-card__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  font-weight: 700;
  margin-top: 6px;
}
.note-card__html {
  margin-top: 8px;
  flex: 1;
}
.note-card--condensed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.note-card--condensed .note-card__more {
  margin-left: 16px;
  margin-right: 16px;
  flex-shrink: 0;
  padding-top: 10px;
}
.note-card--condensed .note-card__title {
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.note-card--condensed .note-card__html > * {
  display: none;
}
.note-card--condensed .note-card__html > *:first-child {
  margin-top: 0;
  display: inherit;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.note-card--condensed .note-card__html pre:first-child {
  padding-top: 0;
  padding-bottom: 0;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.note-card__editor {
  margin-top: 16px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.note-card__editor .rich-text-editor h1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 8px;
}
.note-card__editor .prosemirror-container {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 1px;
}
.desktop .note-card__editor .prosemirror-container {
  padding-right: calc(24px - var(--scrollbar-width));
}

.note-card__editor .rich-text-editor__toolbar {
  margin-left: 12px;
  margin-right: 12px;
}

.task,
.task > * {
  cursor: default !important;
}
.task--editable,
.task--editable > * {
  cursor: pointer !important;
}
.task__date {
  white-space: nowrap;
}
.task__date--past {
  color: #e53935 !important;
}
.task__date--near {
  color: #f57c00 !important;
}
.task__assignee {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #424242;
  white-space: nowrap;
  display: flex;
}
.task__assignee > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.task-edit-dialog__content {
  padding-top: 16px;
}
.task-edit-dialog__row {
  padding-top: 16px;
}
.task-edit-dialog__assignee {
  width: 100%;
}
.task-edit-dialog__sub-action {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
  padding-right: 8px;
  text-align: right;
}
.mobile .task-edit-dialog__sub-action {
  margin-top: 16px;
  margin-bottom: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.video-player {
  z-index: 1998;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-player .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.video-player .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.video-player .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.mobile .video-player {
  background-color: black;
}

.video-player__close {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: opacity 175ms;
  opacity: 0;
  position: absolute;
  z-index: 2;
}
.video-player__close--shown {
  opacity: 1;
}
.mobile .video-player__close {
  top: 0;
  right: 16px;
}

.desktop .video-player__close {
  background-color: #424242;
  border-radius: 50%;
  margin-top: -16px;
  margin-left: -16px;
}

.video-player__transcoding-hint {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  padding: 16px;
  position: absolute;
  z-index: 1;
}
.mobile .video-player__transcoding-hint {
  top: 0;
  left: 0;
  right: 0;
  padding-right: 80px;
  background-color: black;
}

.desktop .video-player__transcoding-hint {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 175ms;
}

.video-player__video {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  background-color: black;
}
.desktop .video-player__video {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: min(720px, 90%);
  max-height: min(480px, 90%);
}
@media (min-width: 900px) and (min-height: 550px) {
  .desktop .video-player__video {
    max-width: 848px;
    max-height: 480px;
  }
}
@media (min-width: 1350px) and (min-height: 800px) {
  .desktop .video-player__video {
    max-width: 1280px;
    max-height: 720px;
  }
}

.mobile .video-player__video {
  max-height: calc(100% - 46px - env(safe-area-inset-bottom));
  top: 46px;
  left: 0;
  right: 0;
  bottom: env(safe-area-inset-bottom);
}

.desktop .comment-menu {
  margin-right: 24px;
}

.comment-menu-mobile__placeholder {
  margin-top: -8px;
  margin-right: -12px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.comments {
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
}
.comments__header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
  margin-left: 24px;
}
.comments__close {
  position: absolute !important;
  top: 14px;
  right: 16px;
}
.comments__show-older {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.comments__content {
  overflow-x: hidden;
  margin: 24px 24px 0;
}
.comments__add {
  border-radius: 10px;
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
  background: #fff;
  /* @alternate */
  background: var(--mdc-theme-surface, #fff);
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 24px 16px 24px;
  padding-right: 16px;
}
.comments__add_editor {
  flex: 1;
  overflow: hidden;
  padding: 16px;
}
.comments__scroll_into_view {
  position: absolute;
  margin-top: 40px;
}

.comment {
  border-radius: 10px;
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}
.comment.comment--new-or-changed:before {
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
  width: 14px;
  margin-right: -14px;
  top: 0;
  bottom: 0;
  content: "";
  border-right: 1px solid #fafafa;
  z-index: 1;
}
.comment .comment__avatar {
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.comment .comment__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 16px;
  flex: 1;
}
.comment .comment__content-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}
.comment .comment__content-header-principal {
  flex: 1;
}
.comment .comment__content-header-date {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  flex-shrink: 0;
}
.comment .comment__content-html {
  margin-top: 4px;
}
.comment .comment__content-removed {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  font-style: italic;
}
.comment--current-user {
  background-color: #e1f5fe;
}

.debug {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.72 !important;
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  padding: 0 16px;
  line-height: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  cursor: default;
  z-index: 2147483647;
}
.debug__error {
  color: #f44336;
  cursor: pointer;
}
.debug__maximized {
  position: fixed;
  color: red;
  background-color: #ffdddd;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  z-index: 2147483647;
  margin: 0;
  border: 2px solid red;
  padding: 16px;
}
.debug__buttons {
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  flex-direction: row;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.board-settings-dialog__header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
}
.board-settings-dialog__board-name {
  margin-top: 16px !important;
  margin-bottom: 16px;
}
.board-settings-dialog__sub-header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-bottom: 8px;
}

.diagnostics-dialog pre {
  max-height: 50vh;
}

.dialog-container__prompt {
  z-index: 1003 !important;
}

.share-board-dialog__intro {
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.share-board-dialog__intro--private {
  background-color: #f5f5f5;
}
.share-board-dialog__intro--shared {
  background-color: #fff9c4;
}
.share-board-dialog__intro--public {
  background-color: #ffe0b2;
}
.share-board-dialog__intro--public-indexed {
  background-color: #ffcdd2;
}
.share-board-dialog__list .mdc-list-item__text {
  flex: 1;
}
.share-board-dialog__acl-full-name {
  min-width: 130px;
  flex: 1;
  margin-right: 16px;
}

body.dnd--dragging * {
  cursor: move;
}
body.dnd--dragging .dnd--hide-on-dragging {
  display: none;
}

.dnd__preview {
  opacity: 0.5;
}

.dnd__ghost {
  position: absolute;
  opacity: 0.9;
}
.dnd__ghost .card__metadata {
  right: 0;
}
.dnd__ghost .card {
  margin: 0 !important;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.import-data-dialog__intro {
  margin-bottom: 32px;
}
.import-data-dialog section.import-data-dialog__start {
  display: flex;
  align-items: center;
}
.import-data-dialog img.import-data-dialog {
  border-radius: 10px;
  width: 80px;
  height: 80px;
  padding: 6px;
  display: block;
  background-color: #eee;
}
.import-data-dialog section.import-data-dialog__collage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80px;
}
.import-data-dialog section.import-data-dialog__collage img.import-data-dialog {
  width: 36px;
  height: 36px;
  float: left;
  margin: 2px;
}
.import-data-dialog div.import-data-dialog__upload {
  display: flex;
}
.import-data-dialog div.import-data-dialog__upload img.import-data-dialog {
  margin-right: 32px;
  width: 110px;
  height: 110px;
  padding: 8px;
}
.mobile .import-data-dialog div.import-data-dialog__upload img.import-data-dialog {
  display: none;
}

.import-data-dialog div.import-data-dialog__upload section.import-data-dialog__collage {
  margin-right: 32px;
  width: 160px;
  height: 120px;
}
.import-data-dialog div.import-data-dialog__upload section.import-data-dialog__collage img.import-data-dialog {
  width: 51px;
  height: 51px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.mobile .import-data-dialog div.import-data-dialog__upload section.import-data-dialog__collage {
  display: none;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
section.meet-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.meet {
  border-radius: 10px;
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  background-color: #212121;
  position: absolute;
  overflow: hidden;
  bottom: calc(60px + env(safe-area-inset-bottom));
  right: 16px;
  width: 640px;
  height: 480px;
  display: flex;
  flex-direction: column;
}
.meet .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.meet .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.meet .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.meet__loading {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  z-index: -1;
}
.meet__emergency-close {
  position: absolute;
  bottom: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.meet__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  z-index: -2;
}
.meet__header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* Disable touch actions for this and all child elements to make DnD work. */
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.2);
  height: var(--top-app-bar-height);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: move;
}
.meet__header--zoomed {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}
.meet__header > * {
  touch-action: none;
}
.meet__header-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  flex: 1;
  text-align: center;
  padding: 0 16px;
}
.meet__header-icon {
  padding: 8px;
}
.meet__header-icon-help {
  cursor: pointer;
}
.meet__header-icon-zoom {
  cursor: pointer;
}
.meet .meet__main {
  background-size: 80px;
  flex: 1;
}
.meet .meet-pre-join {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.meet .meet-pre-join__loading {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  text-align: center;
  margin-bottom: 16px;
}
.meet .meet-pre-join__participants {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 152px;
  padding-bottom: 10px;
}
.meet .meet-pre-join__participants > * {
  margin-right: -10px;
  margin-bottom: -10px;
  flex-shrink: 0;
}
.meet .meet-pre-join__participants-outro {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 32px;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 32px;
}
.meet .meet-pre-join__actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.meet .meet-pre-join__actions > * {
  margin-left: 8px;
  margin-right: 8px;
}
.meet .meet-pre-join.meet-pre-join--text-only {
  background: #e53935 !important;
  padding: 32px !important;
}
.meet--zoom {
  border-radius: 0;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0px !important;
  width: unset !important;
  height: unset !important;
}
.meet__drag-layer {
  position: absolute;
  z-index: 999;
}

@media (max-width: 999px) {
  .meet {
    right: 16px;
    width: 480px;
    height: 360px;
    bottom: calc(var(--bottom-app-bar) + env(safe-area-inset-bottom));
  }
  .meet .meet-pre-join__participants {
    max-height: unset;
    flex-wrap: nowrap;
  }
  .meet .meet-pre-join__participants > * {
    margin-right: -17px;
    margin-bottom: unset;
  }
}
@media (max-width: 599px) {
  .meet {
    right: 16px;
    width: 320px;
    height: 240px;
  }
  .meet .meet-pre-join {
    padding: 0;
  }
  .meet .meet-pre-join__participants {
    max-height: unset;
    flex-wrap: nowrap;
  }
  .meet .meet-pre-join__participants > *.principal-info__avatar-small {
    margin-right: -8px;
    margin-bottom: unset;
  }
  .meet .meet-pre-join__participants-outro {
    margin-bottom: 16px;
  }
}
body.hp.desktop .board-page__top-app-bar, body.hp.mobile .board-page__top-app-bar {
  background-color: rgba(160, 2, 75, 0.8) !important;
}
body.hp.desktop .board-title {
  margin-top: -1px;
}
body.hp.desktop .board-title__text {
  border-radius: 0;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  padding: 0;
  background-color: transparent;
}
body.hp.hp--extra .board-column, body.hp.hp--extra.mobile .board-column {
  padding: 0 !important;
}
body.hp.hp--extra .board-page__container {
  top: 0;
  bottom: 0;
}
body.hp.hp--extra .hp__image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 875ms ease-in;
}
body.hp.hp--extra .card--color-0 {
  background-color: #ffffff !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-0.card {
  background-color: rgba(255, 255, 255, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-1 {
  background-color: #fff475 !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-1.card {
  background-color: rgba(255, 244, 117, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-2 {
  background-color: #ffc84d !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-2.card {
  background-color: rgba(255, 200, 77, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-3 {
  background-color: #f9a4a4 !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-3.card {
  background-color: rgba(249, 164, 164, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-4 {
  background-color: #fdcfe8 !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-4.card {
  background-color: rgba(253, 207, 232, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-5 {
  background-color: #d7aefb !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-5.card {
  background-color: rgba(215, 174, 251, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-6 {
  background-color: #aecbfa !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-6.card {
  background-color: rgba(174, 203, 250, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-7 {
  background-color: #a4effb !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-7.card {
  background-color: rgba(164, 239, 251, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-8 {
  background-color: #bafda9 !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-8.card {
  background-color: rgba(186, 253, 169, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-9 {
  background-color: #e6c9a8 !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-9.card {
  background-color: rgba(230, 201, 168, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-10 {
  background-color: #e8eaed !important;
  background-image: unset;
}
body.hp.hp--extra .card--color-10.card {
  background-color: rgba(232, 234, 237, 0.38) !important;
  background-image: unset;
}
body.hp.hp--extra .link-card__mode-image--banner {
  padding: 32px 0 16px;
}
body.hp.hp--extra .fwc__spacer {
  display: none;
}
body.hp.hp--extra .fwc__main-columns {
  left: 0 !important;
  width: 100% !important;
}
body.hp.hp--extra .fwc__main-column {
  width: 100% !important;
  max-width: unset !important;
  transform: none !important;
  padding: 0 !important;
}
body.hp.hp--extra .card--level-2 .note-card__title {
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.003333em;
}
body.hp.hp--extra .card--level-2 .note-card__html {
  font-size: 22px !important;
  line-height: 36px !important;
}
body.hp.hp--extra .card--level-2 .note-card__html--with-title {
  margin-top: 20px;
}
body.hp.hp--extra .card--color-1 {
  background-color: white !important;
  margin-bottom: -32px;
}
body.hp.hp--extra .card--color-2 {
  background-color: #e8eaed !important;
  margin-bottom: -32px;
}
body.hp.hp--extra .image-card.image-card--cinema {
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}
@media (min-width: 1200px) {
  body.hp.hp--extra .image-card.image-card--cinema {
    padding-top: 4% !important;
    padding-bottom: 4% !important;
  }
}
@media (min-width: 1400px) {
  body.hp.hp--extra .image-card.image-card--cinema {
    padding-top: 6% !important;
    padding-bottom: 6% !important;
  }
}
@media (min-width: 1600px) {
  body.hp.hp--extra .image-card.image-card--cinema {
    max-width: 1400px;
  }
}
@media (min-width: 2000px) {
  body.hp.hp--extra .image-card.image-card--cinema {
    padding-top: 8% !important;
    padding-bottom: 8% !important;
  }
}
body.hp.hp--extra .card-details:not(.link-card__mode):not(.note-card) {
  padding: 0 20%;
}
body.hp.hp--extra .board-column > div[data-card-uid]:first-child > .card .note-card {
  margin-top: 144px !important;
}
body.hp.hp--extra .board-column > div[data-card-uid]:nth-last-child(2) > .card .note-card {
  margin-bottom: 144px !important;
}
body.hp.hp--extra .image-card__card-color-marker {
  display: none;
}
body.hp.hp--extra .card {
  --card-vert-gap: 0px;
}
body.hp.hp--extra .card, body.hp.hp--extra .card__content,
body.hp.hp--extra .card .mdc-card {
  border-radius: 0 !important;
}
body.hp.hp--extra .card-details.image-card {
  cursor: default !important;
  pointer-events: none;
}
body.hp.hp--extra .board__column-spacer {
  margin-bottom: 0;
}
body.hp.hp--extra .card-with-icon__title,
body.hp.hp--extra .note-card__html {
  font-size: 18px !important;
  line-height: 28px !important;
}
body.hp.hp--extra .card-with-icon__title {
  margin-left: 16px;
}
body.hp.hp--extra .card-with-icon__caption {
  display: none;
}
body.hp.hp--extra .card-with-icon {
  padding-left: 48px;
}
body.hp.hp--extra .board-title {
  font-family: Proxima, Arial, sans-serif;
  font-weight: 400;
  font-size: 18px !important;
  margin-left: 16px;
  margin-top: -2px;
}
body.hp.hp--extra.mobile .board-title {
  margin-left: 0;
}
body.hp.hp--extra .note-card {
  margin: 48px 0 0;
  padding: 0 48px;
}
body.hp.hp--extra .note-card__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
  font-family: Proxima, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
}
body.hp.hp--extra .note-card__html--with-title {
  margin-top: 32px;
}
body.hp.hp--extra .note-card__html a {
  font-weight: 700;
}
body.hp.hp--extra .link-card__content-title {
  color: #1976d2 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2) !important;
  white-space: normal;
  max-height: 60px;
}
body.hp.hp--extra .link-card__mode-domain {
  display: none;
}
body.hp.hp--extra .link-card__mode-image .image-card__title--cinema,
body.hp.hp--extra .link-card__mode-image .image-card__title--cinema * {
  text-decoration: none !important;
}
body.hp.hp--extra .file-card[download="nodownload.bin"] {
  cursor: default !important;
  pointer-events: none;
}
body.hp.hp--extra .file-card[download="nodownload.bin"] > .card-with-icon__icon-outline {
  cursor: pointer !important;
  pointer-events: all !important;
}
body.hp.hp--extra .file-card > .card-with-icon__icon-outline {
  margin-left: 48px;
}
body.hp.hp--extra .image-card, body.hp.hp--extra .image-card * {
  cursor: pointer !important;
}
body.hp.hp--extra .image-card__title--cinema {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
  margin: 32px;
  font-family: Proxima, Arial, sans-serif;
  font-weight: 400;
}
body.hp.hp--extra .image-card__title {
  font-family: Proxima, Arial, sans-serif;
}
body.hp.mobile.hp--extra .board-chrome[data-board-columns="1"] .note-card {
  padding: 0 7%;
}
body.hp.mobile.hp--extra .board-chrome[data-board-columns="1"] .card-details:not(.link-card__mode) {
  padding: 0 7%;
}
body.hp.mobile.hp--extra .image-card.image-card--cinema {
  margin-top: -48px;
  margin-bottom: -48px;
  min-width: 140vw;
  padding-top: 0;
  padding-bottom: 0;
}
body.hp.desktop.hp--extra .card--level-2 .note-card {
  padding: 0;
  max-width: 900px;
  min-width: 900px;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (max-width: 960px) {
  body.hp.desktop.hp--extra .card--level-2 .note-card {
    max-width: 700px;
    min-width: 700px;
  }
}
@media (max-width: 760px) {
  body.hp.desktop.hp--extra .card--level-2 .note-card {
    max-width: 500px;
    min-width: 500px;
  }
}
body.hp .link-card__mode-image--banner {
  background-color: white;
  overflow: visible;
}
body.hp .link-card__mode-image--banner .link-card__mode-domain {
  display: none;
}
body.hp .link-card__mode-image--banner .image-card__card-color-marker {
  display: none;
}
body.hp .link-card__mode-image--banner .image-card__title--banner {
  left: 0;
  right: 0;
}
body.hp .link-card__mode-image--banner .image-card__title--banner * {
  text-decoration: none !important;
  letter-spacing: unset !important;
  -webkit-text-stroke: transparent !important;
  margin-top: 0;
}
body.hp .link-card__mode-image--banner .image-card__title--banner *:after {
  border: none !important;
}
body.hp .link-card__mode-image--banner .image-card__title--banner .link-card__mode-title {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  margin: 32px 16px;
  border: 1px solid white;
  padding: 8px 16px;
  font-size: 16px;
}
body.hp .link-card__mode-image--banner .image-card__title--banner .link-card__mode-title:hover {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.hp .card--color-1 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #ffeb3b;
  color: black;
}
body.hp .card--color-2 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #ff5722;
}
body.hp .card--color-3 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #e53935;
}
body.hp .card--color-4 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #e91e63;
}
body.hp .card--color-5 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #9c27b0;
}
body.hp .card--color-6 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #1e88e5;
}
body.hp .card--color-7 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #00acc1;
}
body.hp .card--color-8 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #43a047;
}
body.hp .card--color-9 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #795548;
}
body.hp .card--color-10 .link-card__mode-image--banner .link-card__mode-title {
  background-color: #e8eaed;
  color: black;
}

.global-backdrop {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.global-backdrop--active {
  display: block;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 1000;
}

.global-drop-and-paste {
  z-index: 2001;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(200, 200, 240, 0.2);
  opacity: 0;
  transition: opacity 175ms;
}
.global-drop-and-paste--appear {
  opacity: 1;
}
.global-drop-and-paste__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
  background-color: #d81b60;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #d81b60);
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  font-weight: 800;
  height: var(--top-app-bar-height);
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introduction-tab {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}
.introduction-tab__img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 180px;
  min-width: 40%;
  margin: 16px;
  flex: 1;
}
@media (max-width: 400px) {
  .introduction-tab__img {
    min-height: 140px;
  }
}
@media (max-width: 360px) {
  .introduction-tab__img {
    min-height: 120px;
  }
}
@media (min-width: 600px) and (min-height: 700px) {
  .introduction-tab__img {
    min-height: 200px;
  }
}
@media (min-width: 800px) and (min-height: 700px) {
  .introduction-tab__img {
    min-height: 240px;
  }
}
@media (max-height: 700px) and (max-width: 700px) {
  .introduction-tab__img {
    display: none;
  }
}
@media (max-width: 360px) {
  .introduction-tab__img {
    display: none;
  }
}
.introduction-tab__text {
  flex: 1 1 200px;
}

.stock-image-chooser__black:not(.mdcx-chip--primary) {
  background-color: #424242;
  color: #f5f5f5;
}
.stock-image-chooser__multicolor:not(.mdcx-chip--primary) {
  background-image: linear-gradient(90deg, #fb8c00 25%, #d81b60 50%, #9c27b0 75%, #1e88e5 100%);
  color: #f5f5f5;
}
.stock-image-chooser__image {
  position: relative;
  width: 100%;
  height: 64px;
  padding: 8px;
}
.stock-image-chooser__image--zoomed {
  height: 160px;
  padding: 16px;
}
.stock-image-chooser__image--multicolor {
  animation: multicolor-bg 30s infinite;
  animation-direction: alternate;
}
@keyframes multicolor-bg {
  0% {
    background-color: #fb923c;
  }
  20% {
    background-color: #ec4899;
  }
  40% {
    background-color: #a855f7;
  }
  60% {
    background-color: #5292c1;
  }
  80% {
    background-color: #5fa97e;
  }
  100% {
    background-color: #9ca3af;
  }
}
.stock-image-chooser__image--right {
  background-position-x: right;
}
.stock-image-chooser__image__pro-badge {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
.stock-image-chooser__image__pattern {
  background-repeat: repeat;
}
.stock-image-chooser__multicolor-badge {
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.stock-image-chooser__multicolor-badge > * {
  background-image: linear-gradient(90deg, #ffa726 25%, #ec407a 50%, #ba68c8 75%, #42a5f5 100%);
  -moz-text-stroke: 1px white;
  -webkit-text-stroke: 1px white;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.stock-image-chooser-color-picker {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  gap: 32px;
}
.stock-image-chooser-color-picker__image {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
}
.stock-image-chooser-color-picker__image--pattern {
  background-repeat: repeat;
  background-size: unset;
  width: 100%;
  height: 100%;
}
.stock-image-chooser-color-picker__grid {
  grid-template-columns: repeat(9, 1fr);
}
.stock-image-chooser-color-picker__grid > * {
  height: 32px !important;
  max-width: 32px !important;
  min-width: 16px !important;
}
.stock-image-chooser-color-picker__hsl {
  display: grid;
  gap: 8px;
  grid-template-columns: max-content 1fr;
}
.stock-image-chooser-color-picker__hsl input[type=range] {
  border-radius: 10px;
  flex: 1;
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  outline: none;
}
.stock-image-chooser-color-picker__hsl input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--thumb-color);
  border: 1px solid #212121;
  outline: 1px solid white;
  cursor: pointer;
}
.stock-image-chooser-color-picker__hsl input[type=range]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--thumb-color);
  border: 1px solid #212121;
  outline: 1px solid white;
  cursor: pointer;
}

.user-media {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.user-media__preview, .user-media__video, .user-media__photo {
  width: 100%;
  z-index: 1;
  max-height: 400px;
}
.user-media__preview--audio-only {
  width: 0;
  height: 0;
}
.user-media__photo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.user-media .user-media__preview--flip {
  transform: scale(-1, 1);
}
.user-media__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  z-index: 2;
}
.user-media__loading--floating {
  position: absolute;
}
.user-media__canvas {
  display: none;
}
.user-media__trash-button, .user-media__record-button, .user-media__stop-button {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  display: table;
  border-radius: 50%;
  padding: 4px;
  margin: 8px;
  border: 1px solid white;
}
.user-media__record-button {
  background-color: #d32f2f;
}
.user-media__record-button .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.user-media__record-button .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.user-media__record-button .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.user-media__stop-button {
  border: 1px solid #d32f2f;
}
.user-media__stop-button .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.user-media__stop-button .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.user-media__stop-button .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.user-media__stop-button > * {
  color: #d32f2f !important;
}

.board-page__container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}
.desktop .board-page__container {
  top: var(--top-app-bar-height);
}

.board-page__content {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.board-page__content--enter-fast {
  opacity: 1;
  transition: opacity 175ms;
}
.board-page__content--enter {
  opacity: 1;
  transition: opacity 525ms ease-out;
}
.board-page__top-app-bar--shown {
  transition: transform 175ms !important;
  transform: translateY(0);
}
.board-page__top-app-bar--hidden {
  transition: transform 175ms !important;
  transform: translateY(calc(-1 * var(--top-app-bar-height)));
}
.board-page__top-app-bar-nav {
  margin-right: -16px;
}
body.mobile .board-page__top-app-bar--search {
  height: var(--top-app-bar-height);
  background-color: #fafafa !important;
}

.desktop .board-page__top-app-bar {
  background-color: transparent !important;
  transition: width 350ms, margin-left 350ms !important;
  width: 100vw;
}
.desktop .board-page__top-app-bar--desktop-board-chooser-open {
  margin-left: 305px;
  width: calc(100vw - 305px);
}

.mobile .board-page__top-app-bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.desktop .board-page__bottom-app-bar {
  left: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  transition: left 350ms !important;
}

.mobile .board-page__bottom-app-bar {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.board-page__bottom-app-bar--shown {
  transition: transform 175ms !important;
  transform: translateY(0);
}
.board-page__bottom-app-bar--hidden {
  transition: transform 175ms !important;
  transform: translateY(calc(var(--bottom-app-bar-height) + 2px + env(safe-area-inset-bottom)));
}
.mobile .board-page__bottom-app-bar .mdc-top-app-bar__section--align-end {
  margin-left: -10px;
}

.public-page__top-app-bar {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: rgba(160, 2, 75, 0.8) !important;
}
.public-page__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
  background-image: url(/c/static/waves_lighter.v2.svg);
}
.public-page__content--center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 92px 32px 100px;
}
.public-page__content--center > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
.public-page__content--center > div > div {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 16px;
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.public-page__content--center > div > div .mdc-tab-bar {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.public-page__login-header {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
  text-align: center;
  margin-bottom: 24px;
}
.public-page__login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-width: 290px;
  padding: 32px;
  justify-content: flex-start;
}
@media (min-width: 800px) {
  .public-page__login-content {
    min-width: 750px;
  }
}
.public-page__login-btn {
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: unset;
  align-self: center;
}
.public-page__login-btn--facebook {
  width: 260px;
  background-color: #1877f2 !important;
}
.public-page__login-btn--apple {
  background-color: #fff !important;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
  width: 260px;
}
.public-page__login-btn--apple .public-page__login-btn-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 22px;
  background-color: white;
  width: 24px;
  height: 24px;
}
.public-page__login-btn--password {
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
  width: 260px;
  background-color: #616161 !important;
}
.public-page__login-btn--password[disabled] {
  opacity: 0.5;
}
.public-page__login-btn--google {
  background-color: #fff !important;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
  width: 260px;
}
.public-page__login-btn--google .public-page__login-btn-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  background-color: white;
  width: 24px;
  height: 24px;
}
.public-page__login-btn-icon {
  margin-right: 16px;
  color: white !important;
}
.public-page__title {
  margin-top: -1px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.share-target-page {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  padding-bottom: env(safe-area-inset-bottom);
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.share-target-page .card,
.share-target-page .card__content {
  border-radius: 0;
}
.share-target-page .card-editor,
.share-target-page .card-preview {
  box-shadow: none !important;
  z-index: 0;
  flex: 1;
  display: flex;
}
.share-target-page .note-card__html {
  margin-top: 0;
}
.share-target-page .ProseMirror h1 {
  margin-top: 0;
}
.share-target-page .card-details.link-card-editor,
.share-target-page .card-details.note-card__editor, .share-target-page__files {
  height: calc(100vh - 142px);
  width: 100vw;
  margin: 0;
  margin-top: 32px;
}
.share-target-page .link-card-editor {
  padding: 8px 24px 24px;
}
.share-target-page__files {
  padding: 0 24px;
  overflow: auto;
}
.share-target-page__button-bar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: flex-end;
}

.checkout-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0 16px 0;
}
.checkout-button__compare-editions {
  display: block;
  margin-top: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.checkout__intro ul {
  column-count: 2;
}
.checkout__currency {
  display: flex;
  justify-content: flex-end;
  margin-top: -32px;
}
.checkout__currency > * {
  max-width: 120px;
}
.checkout__yearly-monthly {
  display: flex;
  flex-direction: row;
  padding: 32px 0;
}
.checkout__yearly-monthly > div {
  border-radius: 10px;
  padding: 32px;
  background-color: #e3f2fd;
  border: 1px solid #2196f3;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout__yearly-monthly > div:first-child {
  margin-right: 32px;
  background-color: #fafafa;
  border: 1px solid #9e9e9e;
}
.checkout__button-outer {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-top: 16px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.checkout__button-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout__button-inner > div {
  margin-top: 16px;
}
.checkout__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin-bottom: 16px;
}
.checkout__price {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline4-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 2.125rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline4-font-size, 2.125rem);
  line-height: 2.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline4-line-height, 2.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline4-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline4-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline4-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline4-text-transform, inherit);
}
.checkout__price-small {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  text-align: center;
}

@media (max-width: 599px) {
  .checkout {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Proxima, Arial, sans-serif;
    /* @alternate */
    font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
    font-size: 13px;
    /* @alternate */
    font-size: var(--mdc-typography-caption-font-size, 13px);
    line-height: 1.25rem;
    /* @alternate */
    line-height: var(--mdc-typography-caption-line-height, 1.25rem);
    font-weight: 400;
    /* @alternate */
    font-weight: var(--mdc-typography-caption-font-weight, 400);
    letter-spacing: normal;
    /* @alternate */
    letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
    text-decoration: inherit;
    /* @alternate */
    text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
    text-transform: inherit;
    /* @alternate */
    text-transform: var(--mdc-typography-caption-text-transform, inherit);
  }
  .checkout__intro ul {
    column-count: 1;
  }
  .checkout__yearly-monthly {
    padding: 16px 0;
    flex-direction: column;
  }
  .checkout__yearly-monthly > div {
    padding: 16px;
  }
  .checkout__yearly-monthly > div:first-child {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.quota-overview__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}
.quota-overview__sub {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-top: 32px;
}
.quota-overview__tip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  margin-top: 16px;
}
.quota-overview-row {
  margin-bottom: 4px;
}
.quota-overview-row__title {
  margin-bottom: 4px;
}
.quota-overview-row__progress {
  margin-top: -6px;
}
.quota-overview-row__value {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.quota-overview-progress--warn .mdc-linear-progress__bar-inner {
  border-color: #f44336;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.search-box {
  border-radius: 10px !important;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.search-box .mdc-icon-button.material-icons {
  color: #616161 !important;
}
.desktop .search-box {
  border: 1px solid #616161 !important;
  max-width: 780px;
}
.desktop .search-box.search-box--options-open {
  border-bottom: 1px solid transparent !important;
  border-radius: 16px 16px 0 0 !important;
}

.mobile .search-box {
  margin-right: 8px;
}

.search-box__first-chipset {
  justify-items: flex-end;
}
.search-box__num-matches {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.72 !important;
  margin-right: 8px;
  margin-left: 16px;
}
.search-box__row {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
.desktop .search-box__row {
  padding: 0 4px;
}

.search-box__fake-placeholder {
  position: absolute;
  color: #9e9e9e;
  font-style: italic;
}
.desktop .search-box__fake-placeholder {
  margin-left: 32px;
}

.mobile .search-box__fake-placeholder {
  margin-left: 48px;
}

.search-box__input {
  flex: 1;
  height: 36px !important;
  border: none;
}
.desktop .search-box__input {
  margin-top: -2px;
}

.search-box__input input {
  height: 36px !important;
  padding: 0 !important;
}
.mobile .search-box__search {
  margin-right: -16px;
}

.search-box__options {
  background-color: #f5f5f5;
  margin-top: 36px;
  position: absolute;
  padding-bottom: 8px;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}
.ios .search-box__options {
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
}

.search-box__options-search-all-boards-hint {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin: 8px;
  margin-bottom: 16px;
  background-color: #ffd600;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.search-box__options-divider {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
  margin-top: 24px;
  margin-bottom: 8px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #616161;
}
.search-box__options-divider:first-child {
  margin-top: 16px;
}
.search-box__options-search {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
}
.search-box__options .mdc-chip-set, .search-box__options-group {
  padding: 0 32px;
  margin-top: 0;
}
.search-box__options .mdc-chip-set {
  align-items: center;
}
.search-box__options .mdc-chip-set .mdc-chip {
  margin: 4px 8px 4px 0;
}
.search-box__options-group {
  display: flex;
  flex-wrap: wrap;
}
.desktop .search-box__options-group > * {
  margin-bottom: 8px;
  margin-right: 16px;
}
.desktop .search-box__options-group > *:last-child {
  margin-right: 0;
}

.mobile .search-box__options-group .mdc-select {
  width: 100%;
  margin-right: 8px;
  margin-bottom: 16px;
}

.desktop .search-box__options-date {
  margin-right: 16px;
  width: 200px;
}

.mobile .search-box__options-date {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 8px;
}

.search-box__options-task-date {
  margin-top: 16px !important;
}
.desktop .search-box__options {
  border-radius: 0 0 16px 16px;
  margin-left: -1px;
  border: 1px solid #616161 !important;
  border-top: none !important;
}

.search-result {
  padding: 16px 32px 180px 48px;
  height: 100%;
  position: relative;
  min-width: 380px;
}
.mobile .search-result {
  padding: 60px 16px calc(180px + env(safe-area-inset-bottom));
  min-width: 332px;
  overflow: scroll;
  height: 100vh;
}

.search-result__no-matches {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
  width: 100%;
  height: 90%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
.search-result__no-matches .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.search-result__no-matches .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.search-result__no-matches .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.search-result__no-matches-content {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 16px;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}
.search-result__no-matches-content .material-icons {
  font-size: 200%;
  margin-right: 8px;
}
.search-result__group-title {
  border-radius: 10px;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-result__group-title .material-icons:not(.mdc-theme--primary):not(.mdc-button__icon):not(:disabled) {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.search-result__group-title .material-icons:disabled {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
  opacity: 0.26 !important;
}
.search-result__group-title .material-icons.mdc-theme--primary {
  color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87));
}
.search-result__group-title,
.search-result__group-title * {
  cursor: pointer;
}
.search-result__group-title-text,
.search-result__group-title .board-title__text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, rgba(255, 255, 255, 0.87)) !important;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0;
  margin-left: 6px;
}
.search-result .card .mdc-card mark {
  background-color: #fdd835 !important;
}
.search-result .card-menu-desktop {
  right: 32px;
}
.search-result .card-menu-mobile__placeholder {
  right: 16px;
}

.organizations-page {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/c/static/waves_lighter.v2.svg);
}
.organizations-page__container {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  margin-bottom: calc(32px + env(safe-area-inset-bottom));
}
.ios .organizations-page__container {
  margin-bottom: calc(96px + env(safe-area-inset-bottom));
}

.organizations-page__tabs-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.organizations-page__tabs-content--no-scroll {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.organizations-page__tabs-content--no-scroll .organizations-page__tab-content {
  overflow: hidden;
}
.organizations-page__tab-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.organizations-page__tab-content--removing {
  opacity: 0.4;
  pointer-events: none;
}
.organizations-page .mdc-card {
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.organizations-page .mdc-card__action-buttons {
  margin-top: 16px;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.toast {
  border-radius: 10px;
  /* @alternate */
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  position: absolute;
  width: 15%;
  min-width: 20rem;
  max-width: 40rem;
  left: 16px;
  bottom: 0;
  padding: 32px;
  z-index: 1998;
  transition: transform 175ms;
  transform: translateY(0);
  margin-bottom: env(safe-area-inset-bottom);
  transform: translateY(-16px);
}
.toast--with-bottom-app-bar {
  transform: translateY(calc(-1 * var(--bottom-app-bar-height) - 16px));
}
.toast__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.toast__progress_bar {
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
}
.toast__progress_bar::-webkit-progress-bar {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.toast__progress_bar::-webkit-progress-value {
  background-color: #1976d2;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #1976d2);
}
.toast__action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 480px) {
  .toast {
    min-width: calc(100% - 22px);
  }
}
.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.board-chooser__new-or-changed-badge {
  top: 6px;
  right: 16px;
}
.mobile .board-chooser__new-or-changed-badge {
  top: 12px;
  right: 18px;
}

.board-chooser-item {
  border-radius: 10px;
  user-select: none;
  min-width: 200px;
  max-width: 400px;
  margin: 0;
}
.board-chooser-item > .mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
}
.board-chooser-item > .mdc-list-item__graphic {
  margin-right: 20px;
}
.board-chooser-item__icon {
  border-radius: 50%;
  min-width: 28px;
  min-height: 28px;
  width: 28px;
  height: 28px;
  position: relative;
}
.board-chooser-item__new-or-changed-badge {
  top: -1px;
  right: -5px;
}
.board-chooser-item__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.board-chooser-item__title *:first-child {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.board-chooser-item__title *:last-child {
  opacity: 0.7;
  margin-left: 8px;
}
.board-chooser-item__title > a {
  display: block;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.error-bubble {
  border-radius: 10px;
  background-color: #b00020;
  font-weight: 600;
  color: #fff;
  padding: 16px;
}

.fwc__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.fwc__main-columns,
.fwc__aux-column--outer {
  position: absolute;
  min-height: 100%;
  top: 0;
  bottom: 0;
}

.fwc__main-columns {
  transform: translateX(0);
  transition: transform 350ms, left 175ms;
  overflow-x: visible !important;
}
.mobile .fwc__main-columns {
  transition: transform 175ms;
}

.fwc__main-columns .ps__rail-x .ps__thumb-x,
.fwc__main-columns .ps__rail-y .ps__thumb-y {
  background-color: #e0e0e0 !important;
  box-shadow: inset 1px 0 0 0 #616161, inset -1px 0 0 0 #616161, inset 0 1px 0 0 #616161, inset 0 -1px 0 0 #616161 !important;
}
.fwc__main-columns .ps__rail-x,
.fwc__main-columns .ps__rail-y {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.fwc__main-columns .ps__rail-y:hover .ps__thumb-y,
.fwc__main-columns .ps__rail-y.ps--clicking .ps__thumb-y,
.fwc__main-columns .ps__rail-x:hover .ps__thumb-x,
.fwc__main-columns .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #616161 !important;
  box-shadow: inset 1px 0 0 0 #f5f5f5, inset -1px 0 0 0 #f5f5f5, inset 0 1px 0 0 #f5f5f5, inset 0 -1px 0 0 #f5f5f5 !important;
}
.fwc__main-columns--hidden {
  visibility: hidden;
  pointer-events: none;
}

.fwc__main-column {
  transition: width 175ms, transform 175ms;
}

.fwc__aux-column--outer {
  transition: transform 175ms;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(50px);
  left: 0;
  top: 1px;
  overflow-x: hidden;
}
.desktop .fwc__aux-column--outer {
  min-height: 100%;
}

.fwc__vertical_scroll-container,
.fwc__main-column,
.fwc__spacer,
.fwc__aux-column--inner {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.fwc__main-column,
.fwc__aux-column--inner {
  overflow-y: auto;
}

.dnd--dragging .fwc__main-column {
  transition: none;
}

html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
article,
section,
dialog,
nav,
main,
aside,
header,
caption,
mark {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  background: unset;
  position: static;
  top: unset;
  left: unset;
  width: unset;
  height: unset;
  bottom: unset;
  right: unset;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  background-color: #f5f5f5;
  overflow: hidden;
}

div {
  -webkit-tap-highlight-color: transparent;
}

textarea {
  border-radius: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  outline: 0;
}
textarea:focus {
  border-color: #1976d2;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #1976d2);
  outline: 0;
  border-width: 2px;
  padding: 7px 15px;
}

.mobile body {
  width: 100%;
  height: 100%;
}

/* Adapt some commonly used tags. */
a,
a:visited {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  -webkit-touch-callout: none !important;
}

a.action,
.action {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  font-weight: 600 !important;
  cursor: pointer;
  white-space: nowrap;
}

a.action-no-font {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.white-space--nowrap {
  white-space: nowrap;
}

input {
  background-color: transparent;
}

.p--1 {
  padding: 16px;
}

.p--1-5 {
  padding: 24px;
}

.p--2 {
  padding: 32px;
}

.pt--1 {
  padding-top: 16px;
}

.pb--1 {
  padding-bottom: 16px;
}

.pt--2 {
  padding-top: 32px;
}

.pb--2 {
  padding-bottom: 32px;
}

.pl--1 {
  padding-left: 16px;
}

.pr--1 {
  padding-right: 16px;
}

.pl--2 {
  padding-left: 32px;
}

.pr--2 {
  padding-right: 32px;
}

.m--0-5 {
  margin: 8px;
}

.m--1 {
  margin: 16px;
}

.m--2 {
  margin: 32px;
}

.mt--0-5 {
  margin-top: 8px;
}

.mt--1 {
  margin-top: 16px;
}

.mt--1-5 {
  margin-top: 24px;
}

.mb--1 {
  margin-bottom: 16px;
}

.mb--1-5 {
  margin-bottom: 24px;
}

.mt--2 {
  margin-top: 32px;
}

.mb--2 {
  margin-bottom: 32px;
}

.ml--0-5 {
  margin-left: 8px;
}

.ml--1 {
  margin-left: 16px;
}

.mr--0-5 {
  margin-right: 8px;
}

.mr--1 {
  margin-right: 16px;
}

.ml--n1 {
  margin-left: -16px;
}

.ml--2 {
  margin-left: 32px;
}

.mr--2 {
  margin-right: 32px;
}

.mr--4 {
  margin-right: 64px;
}

.gap--0-5 {
  gap: 8px;
}

.gap--1 {
  gap: 16px;
}

.gap--2 {
  gap: 32px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap--wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink--0 {
  flex-shrink: 0;
}

.align-items--center {
  align-items: center;
}

.justify-content--center {
  justify-content: center;
}

.text-align--right {
  text-align: right;
}

.text-align--center {
  text-align: center;
}

.float--left {
  float: left;
}

.font-overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 17px;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 17px);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 600);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, normal);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: none;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, none);
}

.font-caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.font-body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 16px;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: 22px;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.font-caption--light {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.6 !important;
}

.font-caption--very-light {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Proxima, Arial, sans-serif));
  font-size: 13px;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 13px);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  opacity: 0.72 !important;
}

.cursor--zoom-in {
  cursor: zoom-in;
}

.cursor--default {
  cursor: default;
}

.width--full {
  width: 100%;
}

.height--full {
  height: 100%;
}

.overflow--hidden {
  overflow: hidden;
}

.overflow--scroll {
  overflow: scroll;
}

.overflow--auto {
  overflow: auto;
  scrollbar-gutter: stable;
}

.overflow--ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.opacity--6 {
  opacity: 0.6;
}

.opacity--8 {
  opacity: 0.8;
}

.p--1 {
  padding: 16px;
}

.z-index--above-all {
  z-index: 1998 !important;
}

a.no-outline, a.no-outline:focus, a.no-outline:hover, a.no-outline:active {
  outline: 0;
}

.error {
  color: #e53935;
}

.danger {
  color: #f44336;
}

.warn-bg {
  background-color: #fff9c4;
}

.error-bg {
  background-color: #ffcdd2;
}

.container-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.disable-all-animations *,
.disable-all-animations *:before,
.disable-all-animations *:after {
  animation: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
}

:root {
  --scrollbar-thumb-color: #bdbdbd;
  --scrollbar-thumb-color-hover: #616161;
  --scrollbar-track-color: rgba(255, 255, 255, 0.4);
  --scrollbar-track-color-hover: rgba(0, 0, 0, 0.075);
  --scrollbar-width: 10px;
}

.desktop {
  /* Firefox
     We have to use the `@supports` barrier, because if `scrollbar-color` is set
     all `-webkit-scrollbar` properties are ignored. So this would mess up the style
     for webkit browsers.
  */
  /* All other browsers. */
}
@supports not selector(::-webkit-scrollbar) {
  .desktop * {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
  }
  .desktop *:hover {
    scrollbar-color: var(--scrollbar-thumb-color-hover) var(--scrollbar-track-color-hover);
  }
}
.desktop *::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}
.desktop *::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: var(--scrollbar-width);
}
.desktop *:hover::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color-hover);
}
.desktop *::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
  border-radius: var(--scrollbar-width);
}
.desktop *:hover::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color-hover);
}

.emoji {
  display: inline-block;
  font-size: 21px;
  line-height: 20px;
  overflow: visible;
}
.emoji--large {
  font-size: 36px;
  line-height: 42px;
  display: block;
}

.rich-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.rich-text > *:first-child:not(h1):not(.emoji),
.rich-text h1 + * {
  margin-top: 0;
}
.rich-text > *:last-child:not(h1):not(.emoji) {
  margin-bottom: 0;
}
.rich-text p,
.rich-text ul,
.rich-text ol,
.rich-text pre {
  margin: 0;
}
.rich-text p {
  padding: 0;
}
.rich-text ul,
.rich-text ol {
  padding: 0;
  margin: 6px 0;
  position: relative;
}
.rich-text ul:not([role=listbox]),
.rich-text ol {
  list-style: none;
  padding-left: 26px;
}
.rich-text ul:not([role=listbox]) > li:before,
.rich-text ol > li:before {
  position: absolute;
  min-width: 27px;
  left: 0;
}
.rich-text ul:not([role=listbox]) > li:before {
  content: "•";
  font-size: 24px;
  line-height: 20px;
}
.rich-text ol > li {
  counter-increment: step-counter;
}
.rich-text ol > li:before {
  content: counter(step-counter) ". ";
}
.rich-text li {
  margin-bottom: 4px;
}
.rich-text ol > li:last-child,
.rich-text ul > li:last-child {
  margin-bottom: 0 !important;
}
.rich-text ul[role=listbox] {
  padding-left: 24px;
  list-style: none;
  margin-left: -3px;
}
.rich-text li[role=option] {
  padding-left: 6px;
}
.rich-text li[role=option]:before {
  cursor: pointer;
  display: block;
  position: absolute;
  content: " ";
  background-size: 20px 20px;
  background-repeat: no-repeat;
  height: 23px;
  width: 24px;
  left: 2px;
  margin-top: 1px;
}
.public .rich-text li[role=option]:before {
  cursor: default;
}

.mobile .rich-text li[role=option] {
  margin-bottom: 8px;
}
.mobile .rich-text li[role=option]:before {
  background-size: 24px 24px;
  height: 27px;
  margin-top: -1px;
  left: 0;
}

.rich-text li[aria-checked=true] {
  color: #757575;
}
.rich-text li[aria-checked=true]:before {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z" fill="rgb(158,158,158)"/></svg>');
}
.rich-text li[aria-checked=false]:before {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" fill="rgb(97,97,97)"/></svg>');
}
.rich-text b {
  font-weight: 700;
}
.rich-text em {
  background-color: #b2ff59;
  font-style: inherit;
}
.rich-text code,
.rich-text pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;
}
.rich-text code {
  padding: 2px;
}
.rich-text pre {
  overflow: auto;
  padding: 1rem;
  word-wrap: normal;
  tab-size: 4;
}
.rich-text pre code {
  padding: 0;
  background-color: unset;
}
.rich-text a,
.rich-text a:visited {
  color: #1976d2;
  /* @alternate */
  color: var(--mdc-theme-primary, #1976d2);
  text-decoration: underline;
}
.rich-text a.malformed,
.rich-text a.malformed:visited {
  color: #e53935;
}

.image-card__broken-icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>');
}

.condensed-thumbnail__no-image {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.file-card__icon {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"/><path d="M0 0h24v24H0z" fill="none"/> </svg>');
}

.card-with-icon__play, .image-card__play {
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="rgb(230, 33, 23)" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8zM10 7.5l6 4.5-6 4.5v-9z"/><path fill="rgb(255, 255, 255)" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8zm-2 3.5v9l6-4.5z"/></svg>');
}

.public-page__login-btn--google .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"%3E%3Cdefs%3E%3Cpath id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/%3E%3C/defs%3E%3CclipPath id="b"%3E%3Cuse xlink:href="%23a" overflow="visible"/%3E%3C/clipPath%3E%3Cpath clip-path="url(%23b)" fill="%23FBBC05" d="M0 37V11l17 13z"/%3E%3Cpath clip-path="url(%23b)" fill="%23EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%2334A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/%3E%3Cpath clip-path="url(%23b)" fill="%234285F4" d="M48 48L17 24l-4-3 35-10z"/%3E%3C/svg%3E');
}

.public-page__login-btn--apple .public-page__login-btn-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="814" height="1000"><path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/></svg>');
}

/**
 * Contains everything that the class .mdc-ripple-surface provides but as a mixin.
 */
/**
 * Always use this mixin to disable a `mdc-elevation` because the implementation could change and
 * we want to fix this in one place only.
 */
.rich-text-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.rich-text-editor--invalid {
  border: 2px solid #b00020;
}
.rich-text-editor .error-bubble {
  border-radius: 0;
  margin-bottom: 16px;
}
.rich-text-editor .prosemirror-container {
  display: flex;
  font-size: inherit;
  font-family: inherit;
  flex: 1;
  overflow: hidden;
}
.rich-text-editor .is-empty::before {
  position: absolute;
  content: attr(data-placeholder);
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

.ProseMirror {
  -webkit-user-select: text;
  flex: 1;
  position: relative;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  padding-bottom: 5px;
}
.ProseMirror:focus {
  outline: 0;
}

.rich-text-editor__toolbar {
  margin-top: 16px;
  padding: 0 8px;
}
.mobile .rich-text-editor__toolbar {
  padding: 0;
}

.rich-text-editor__toolbar-inner {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
}
.desktop .rich-text-editor__toolbar-inner {
  max-width: 400px;
}

.mobile .rich-text-editor__toolbar-icon {
  margin: 0 -4px;
}

#emoji-picker-container {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
}

#emoji-picker-container .emoji-mart-title-label {
  font-size: 14px;
}

/*# sourceMappingURL=_sass_all.css.map */
